import { PageTheme, genPageTheme } from '@backstage/theme';
import { colourDictionary } from './colors';

const shapes2: Record<string, string> = {
  blank: `url("data:image/svg+xml,%3csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='1400.000000pt' height='400.000000pt' viewBox='0 0 1400.000000 400.000000' preserveAspectRatio='xMidYMid meet'%3e %3cg transform='translate(0.000000%2c400.000000) scale(0.100000%2c-0.100000)' fill='black' stroke='none'%3e%3c/g%3e%3c/svg%3e")`,
};

export const mnsPageTheme: Record<string, PageTheme> = {
  home: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  documentation: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  tool: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  service: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  website: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  library: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  other: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  app: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
  apis: genPageTheme({
    colors: [
      colourDictionary.mnsTech.prussianBlue,
      colourDictionary.mnsTech.prussianBlue,
    ],
    shape: shapes2.blank,
  }),
};

export const commonPalette = {
  bursts: {
    fontColor: colourDictionary.grey[50],
    slackChannelText: colourDictionary.grey[300],
    backgroundColor: {
      default: colourDictionary.red[400],
    },
    gradient: {
      linear:
        'linear-gradient(-137deg, colourDictionary.green[600] 0%, colourDictionary.green[800] 100%)',
    },
  },
  primary: {
    main: colourDictionary.orange[950],
    light: '#4C9AFF',
    dark: '#172B4D',
    fontText: '#ffff'
  },
  secondary: {
    main: colourDictionary.green[400],
  },
  border: colourDictionary.white,
  textContrast: colourDictionary.black,
  textVerySubtle: colourDictionary.grey[50],
  textSubtle: colourDictionary.grey[600],
  highlight: colourDictionary.orange[100],
  errorBackground: colourDictionary.red[50],
  warningBackground: colourDictionary.yellow[100],
  infoBackground: colourDictionary.blue[200],
  errorText: colourDictionary.grey[400],
  warningText: colourDictionary.black,
  linkHover: colourDictionary.orange[950],
  link: colourDictionary.orange[950],
  gold: colourDictionary.orange[500],
  pinSidebarButton: {
    icon: colourDictionary.grey[900],
    background: colourDictionary.grey[400],
  },
};

