import React from 'react';

const logoStyle: React.CSSProperties = {
  width: "75px",
  position: "relative",
  left: "-25px"
  // Add more styles as needed
};

const LogoIcon: React.FC = () => {
  return <img src="/D_T_Icon.png" style={logoStyle} alt="cloud9" />;
};

export default LogoIcon;