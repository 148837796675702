import React, { useEffect } from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { capitalize, LinearProgress } from '@material-ui/core';
import {
  useApi,
  fetchApiRef,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { Alert, AlertTitle } from '@material-ui/lab';

let serviceAccountList: serviceAccountType[] = [];

/**  */
type serviceAccountType = {
  account: string;
};

export const ServiceAccountsCreationLimiter = () => {
  /** state  */
  const [accountList, setAccountList] = React.useState<serviceAccountType[]>(
    [],
  );
  const [apiCallLoader, setApiCallLoader] = React.useState(true);
  const [triggerStatus, setTriggerStatus] = React.useState(false);

  const fetchApi = useApi(fetchApiRef);
  const discoveryApi = useApi(discoveryApiRef);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const environments = { "dev": "Non-Production", "prod": "Production" }
  const mra = false


  useEffect(() => {
    if (!triggerStatus) {
      setTriggerStatus(true);
      serviceAccountList = [];
      discoveryApi.getBaseUrl('confluentcloud').then(baseUrl => {
        fetchApi
          .fetch(`${baseUrl}/getUserServiceAccountsList`).then(response => response.json())
          .then(apiResult => {
            setApiCallLoader(false);
            if (JSON.parse(JSON.stringify(apiResult))?.documents.length > 0) {
              JSON.parse(JSON.stringify(apiResult))?.documents?.map(
                (each: any) => {
                  serviceAccountList.push(each.account);
                },
              );
              if (accountList.length === 0) setAccountList(serviceAccountList);
            } else {
              setAccountList([]);
            }
          });
      });
    }
  }, [accountList.length, discoveryApi, environments, fetchApi, mra, triggerStatus]);
  return (
    <>
      {apiCallLoader ? (
        <LinearProgress variant="buffer" value={0} valueBuffer={0} />
      ) : (
        <></>
      )}
      {accountList.length === 1 ? (
        <>
          <Alert severity="warning">
            <AlertTitle>Warning</AlertTitle>
            <strong> {capitalize('Found 1 Service Account')}</strong>, A Team is
            allowed to create only two account in confluent cloud —{' '}
            <strong>
              Contact{' '}
              <a
                href="https://outlook.office.com/?path=/mail/action/compose&to=grp-eventplatform@mnscorp.onmicrosoft.com&subject=BUG:%3Cplease%20fill%20the%20email%20subject%20without%20removing%20tag%3E&body=%3CAdd%20Your%20Request%20here%3E"
                target="_blank"
              >
                Confluent Kafka Team
              </a>{' '}
            </strong>{' '}
            for more details
          </Alert>
        </>
      ) : (
        <></>
      )}
      {accountList.length >= 2 ? (
        <>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <strong> {capitalize('Found 2 or more service accounts')}</strong>,
            A Team is allowed to create only two account in confluent cloud —{' '}
            <strong>
              Contact{' '}
              <a
                href="https://outlook.office.com/?path=/mail/action/compose&to=grp-eventplatform@mnscorp.onmicrosoft.com&subject=BUG:%3Cplease%20fill%20the%20email%20subject%20without%20removing%20tag%3E&body=%3CAdd%20Your%20Request%20here%3E"
                target="_blank"
              >
                Confluent Kafka Team
              </a>{' '}
              !{' '}
            </strong>{' '}
            for more details
          </Alert>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const ServiceAccountValidation = (validation: FieldValidation) => {
  if (serviceAccountList.length >= 2) {
    validation.addError('');
  }
};
