import React from 'react';
import { EmptyState } from '@backstage/core-components';
import { EntitySwitch } from '@backstage/plugin-catalog';

import { Button } from '@material-ui/core';
import { isGithubActionsAvailable, GithubActionsAsService, EntityGithubActionsContent } from '@internal/backstage-plugin-github-actions';


export const CICDTabContent = (
  <EntitySwitch>
    {/* <EntitySwitch.Case if={isGithubActionsAvailable}>
      <GithubActionsAsService />
      <EntityGithubActionsContent />
    </EntitySwitch.Case> */}
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <GithubActionsAsService />
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
