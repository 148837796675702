import { InfoCard } from '@backstage/core-components';
import {
  useApi,
  discoveryApiRef,
  identityApiRef,
  appThemeApiRef,
} from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Button,
  Dialog,
  DialogContentText,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  WithStyles,
  createStyles,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { useAsync } from 'react-use';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

/** Widget Constants */
const WIDGET_TITLE = 'Deploy to DeploymentType';
const filePath = 'cicdaas/template_cicdaas_config.json';

const useStyles = makeStyles(
  (theme: { palette: any; spacing: (arg0: number) => any }) => ({
    formControl: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      minWidth: 300,
    },
    buttonDefault: {
      textTransform: 'none',
      fontWeight: 100,
      TextAlign: 'center',
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: 1500 + 1,
      color: '#fff',
    },
  }),
);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

enum DeploymentType {
  PROD = 'prod',
  NON_PROD = 'non-prod',
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const EnvDeployer = () => {
  const classes = useStyles(); // Get the styles from the useStyles function
  const entity = useEntity(); // Get the entity from the useEntity hook

  const repoName = entity?.entity?.metadata?.annotations?.["github.com/project-slug"].split("/")[1] // Get the name of the repo from the entity

  const discoverApi = useApi(discoveryApiRef); // Get the discoveryApiRef
  const identityApi = useApi(identityApiRef); // Get the identityApiRef
  const themeApi = useApi(appThemeApiRef);

  
  const timer = React.useRef();

  const baseUrl = useAsync(async (): Promise<any> => {
    return await discoverApi.getBaseUrl('github');
  }).value; // Get the base url from the discoveryApiRef

  const token = useAsync(async (): Promise<any> => {
    return (await identityApi.getCredentials()).token;
  }).value; // Get the token from the identityApiRef


  const user = useAsync(async (): Promise<any> => {
    return (await identityApi.getProfileInfo()).displayName;
  }).value;

  const [deployType, setDeployType] = React.useState<DeploymentType>(
    DeploymentType.NON_PROD,
  ); // Set the default deployment type to non-prod
  const [userEnvLoader, setUserEnvLoader] = React.useState(true); //  Set the default deployment type to non-prod

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // Handle the change in the deployment type
  const handleDeploymentTypeChange = (event: any) => {
    setDeployType(event.target.value as DeploymentType); // Set the deployment type
    setDeploymentEnv(''); //    Set the deployment environment to empty
    setUserEnvLoader(true); // Set the userEnvLoader to true
    setDeploymentEnvOptions([]); // Set the deployment environment options to empty
    if ((event.target.value as DeploymentType) === DeploymentType.NON_PROD) {
      // If the deployment type is non-prod
      setDeploymentEnvOptions(nonProdDeploymentEnv); // Set the deployment environment options to non-prod
    } else {
      setDeploymentEnvOptions(prodDeploymentEnv); // Set the deployment environment options to prod
    }
    setUserEnvLoader(false); // Enable deploymentEnvOptions by setting the userEnvLoader to false
  };

  // Handle the change in the deployment environment
  const handleDeploymentEnvChange = (event: any) => {
    setDeploymentEnv(event.target.value as string); // Set the deployment environment
    setUserReleaseTagLoader(false); // Enable releaseTagOptions by setting the userReleaseTagLoader to false
  };

  const [nonProdDeploymentEnv, setNonProdDeploymentEnv] = React.useState([]); // Set the default non-prod deployment environment to empty
  const [prodDeploymentEnv, setProdDeploymentEnv] = React.useState([]); // Set the default prod deployment environment to empty
  const [DeploymentEnvOptions, setDeploymentEnvOptions] = React.useState([]); // Set the default deployment environment options to empty
  const [DeploymentEnv, setDeploymentEnv] = React.useState<string>(''); // Set the default deployment environment to empty

  // Fetch the deployment environment options from the cicdaas_config.json file
  const deploymentEnvLoader = useAsync(async (): Promise<any> => {
    return await fetch(
      `${baseUrl}/file/content?repo=${repoName}&path=${filePath}`,
      {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${token}`,
        },
      },
    )
      .then(response => response.json())
      .then(data => {
        setNonProdDeploymentEnv(
          data.nonprod_deployment_environment
            .replaceAll(';', ',')
            .split(',')
            .map((env: string) => ({ env: env.toLowerCase() })),
        );
        setProdDeploymentEnv(
          data.prod_deployment_environment
            .replaceAll(';', ',')
            .split(',')
            .map((env: string) => ({ env: env.toLowerCase() })),
        );
        setDeploymentEnvOptions(
          data.nonprod_deployment_environment
            .replaceAll(';', ',')
            .split(',')
            .map((env: string) => ({ env: env.toLowerCase() })),
        );
      });
  }, [baseUrl, token, repoName, filePath]);

  const [userReleaseTagLoader, setUserReleaseTagLoader] = React.useState(true); // Set the default userReleaseTagLoader to true
  const [ReleaseTagOptions, setReleaseTagOptions] = React.useState([]); // Set the default releaseTagOptions to empty
  const [releaseTag, setReleaseTag] = React.useState<string | undefined>(); // Set the default releaseTag to empty

  const [workflowID, setWorkflowID] = React.useState([]);
  const [backDrop, setBackDrop] = React.useState(false);
  const [backDropSuccess, setBackDropSuccess] = React.useState(false);
  const [backDropFailed, setBackDropFailed] = React.useState(false);

  // Handle the change in the release tag
  const handleReleaseTagChange = (_event: any, value: any) => {
    setReleaseTag(value?.tag as string); // Set the release tag
  };

  // Fetch the release tag options from the cicdaas_config.json file
  useAsync(async (): Promise<any> => {
    return await fetch(`${baseUrl}/list/tags?repo=${repoName}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setReleaseTagOptions(data.map((each: any) => ({ tag: each.name })));
      });
  }, [baseUrl, token, repoName, filePath]);

  // Get the workflow id of the invoke-cicdaas-deployment workflow
  useAsync(async (): Promise<any> => {
    return await fetch(`${baseUrl}/list/workflows?repo=${repoName}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
    })
      .then(response => response.json())
      .then(data => {
        setWorkflowID(
          data?.workflows?.filter(
            (each: any) => each.name === 'invoke-cicdaas-deployment',
          )[0].id,
        );
      });
  }, [baseUrl, token, repoName]);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setdeploytag(undefined);
  };

  const handleSubmit = async () => {
    setBackDrop(true);
    setBackDropFailed(false);
    setBackDropSuccess(false);
    const response = await fetch(`${baseUrl}/event/workflow_dispatcher`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        workflow_id: workflowID,
        repo: repoName,
        ref: 'main',
        inputs: {
          deployment_env: DeploymentEnv,
          image_tag: releaseTag,
          comment: `Triggered By ${user} from ${window.location.hostname.replace(
            'cloud.mnscorp.net',
            '',
          )} with env as ${DeploymentEnv} & tag =  ${releaseTag}`,
        },
      }),
    }).then(response => response.json());

    if (response.status == 204) {
      setBackDropSuccess(true);
      setBackDrop(false);
      setTimeout(() => {
        setOpen(false);
        setdeploytag(undefined);
        setBackDropFailed(false);
        setBackDropSuccess(false);
      }, 2000);
    } else {
      setBackDropFailed(true);
      setBackDrop(false);
      setTimeout(() => {
        setOpen(false);
        setdeploytag(undefined);
        setBackDropFailed(false);
        setBackDropSuccess(false);
      }, 2000);
    }
  };

  const [deploytag, setdeploytag] = React.useState();

  return (
    <>
      <InfoCard title={WIDGET_TITLE}>
        <FormControl className={classes.formControl}>
          <InputLabel id="deloymentType-select-label">
            Select Deployment Type
          </InputLabel>
          <Select
            labelId="deloymentType-select-label"
            id="deloymentType-select"
            value={deployType}
            onChange={handleDeploymentTypeChange}
          >
            <MenuItem value={DeploymentType.NON_PROD}>Non-Production</MenuItem>
            <MenuItem value={DeploymentType.PROD}>Production</MenuItem>
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <InputLabel id="deloymentType-select-label">
            Select Environment
          </InputLabel>
          <Select
            labelId="deloymentEnv-select-label"
            id="deloymentEnv-select"
            value={DeploymentEnv}
            onChange={handleDeploymentEnvChange}
            disabled={deploymentEnvLoader.loading && userEnvLoader}
          >
            {/* <MenuItem disabled value={"undefined"}>Select</MenuItem> */}
            {DeploymentEnvOptions.length != 0 ? (
              DeploymentEnvOptions.map((value: any, index) => {
                return (
                  <MenuItem key={index} value={value.env}>
                    {value.env}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value={''}>No Options</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Autocomplete
            onChange={handleReleaseTagChange}
            loading={userReleaseTagLoader}
            options={ReleaseTagOptions}
            getOptionLabel={(option: any) => option.tag}
            style={{ width: 300 }}
            renderInput={params => (
              <TextField {...params} label="Select Release tag to Deploy " />
            )}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.buttonDefault}
            disabled={releaseTag == undefined || DeploymentEnv.length == 0}
            onClick={handleButtonClick}
            startIcon={<FlightTakeoffIcon />}
          >
            Deploy
          </Button>
        </FormControl>
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" onClose={handleClose}>
              Are you absolutely sure ?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Once action is initiated it cannot be stopped, This will deploy{' '}
                <Button
                  endIcon={<AssignmentIcon />}
                  onClick={() =>
                    navigator.clipboard.writeText(
                      deployType + '/' + DeploymentEnv + '/' + releaseTag,
                    )
                  }
                  color="primary"
                >
                  <b>
                    {deployType}/{DeploymentEnv}/{releaseTag}
                  </b>
                </Button>{' '}
                <br /> <br /> Please type in the highlighted text to confirm the
                deployment
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                fullWidth
                onChange={(event: any) => {
                  setdeploytag(event.target.value);
                }}
              />
              <Backdrop className={classes.backdrop} open={backDrop}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Backdrop className={classes.backdrop} open={backDropSuccess}>
              { themeApi.getActiveThemeId()  == 'dark' ?(<img src={'/dark_theme_tick.svg'} alt="done" width={120} />):(<img src={'/light_theme_tick.png'} alt="done" width={150} />)}
                
              </Backdrop>
              <Backdrop className={classes.backdrop} open={backDropFailed}>
                <img src={'/error.png'} alt="done" width={120} />
              </Backdrop>
            </DialogContent>
            <DialogActions>
              <Button
                fullWidth
                variant="contained"
                disabled={
                  deploytag !==
                  deployType + '/' + DeploymentEnv + '/' + releaseTag
                }
                onClick={handleSubmit}
                color="primary"
                className={classes.buttonDefault}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </InfoCard>
    </>
  );
};
