import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  MandSEmailValidation,
  EmailValidation,
  UserEmailExtensionWithOptionsSchema
} from './UserEmailValidation';

export const UserEmailValidationExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'mands-email',
    component: MandSEmailValidation,
    validation: EmailValidation,
    schema: UserEmailExtensionWithOptionsSchema,
  }),
);
