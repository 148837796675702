import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import { ComponentHopper, ComponentHopperValidation } from './ComponentHopper';

export const ComponentHopperFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'component-hopper',
    component: ComponentHopper,
    validation: ComponentHopperValidation,
  }),
);
