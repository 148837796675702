import React, { useEffect } from 'react';
import type { FieldValidation } from '@rjsf/utils';
import { githubAuthApiRef, alertApiRef, useApi } from '@backstage/core-plugin-api';
import FormControl from '@mui/material/FormControl';
import { TextField } from '@material-ui/core';
import logger from './logger';


let uiSchemaOptions: any;

export const BannerForUserRestriction = ({
    onChange,
    uiSchema,
    required,
    rawErrors,
}: any) => {
    const alertApi = useApi(alertApiRef);
    uiSchemaOptions = uiSchema['ui:options'];
    const excludeOptions: string[] = uiSchemaOptions?.exclude || ['TCS', 'Tata Consultancy Services'];
    const excludeOptionsLowerCase = excludeOptions.map((option: string) => option.toLowerCase());
    const getUserProfile = useApi(githubAuthApiRef);

    const getUserInfo = async (token: string) => {
        try {
            const response = await fetch('https://api.github.com/user', {
                method: 'GET',
                headers: {
                    'Accept': 'application/vnd.github+json',
                    'Authorization': `Bearer ${token}`,
                    'X-GitHub-Api-Version': '2022-11-28',
                },
            });
            const data = await response.json();
            const userInfo = { login: data.login, company: data.company };
            return userInfo;
        } catch (error) {
            logger.warn(`Failed to fetch user info ${error}`);
            throw error;
        }
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const userToken = await getUserProfile.getAccessToken();
                const userInfo = await getUserInfo(userToken);
                if (userInfo) {
                    const { login, company } = userInfo;
                    let errorMessageTemplate: string = uiSchemaOptions?.errorMessage
                        ? uiSchemaOptions.errorMessage
                        : `The user with Github UserId: ${login} is part of the company: ${company} and is not allowed to request access for Github CoPilot`;

                    errorMessageTemplate = errorMessageTemplate.replace("{login}", login).replace("{company}", company);
                    if (excludeOptionsLowerCase.includes(userInfo?.company.toLowerCase()) || userInfo?.company === '' || userInfo?.company === undefined) {
                        onChange(userInfo?.company);
                        alertApi.post({
                            message: errorMessageTemplate,
                            severity: 'error',
                        });
                    }
                } else {
                    logger.warn('Failed to fetch user info');
                }
            } catch (error) {
                logger.error(`Failed to fetch user profile ${error}`);
            }
        };
        fetchUserProfile();
    }, [alertApi, excludeOptionsLowerCase, onChange, getUserProfile]);

    return (
        <FormControl required={required} error={rawErrors?.length > 0}>
            <TextField InputProps={{ disableUnderline: true }} />
        </FormControl>
    );
};

export const BannerForUserRestrictionValidation = (value: string, validation: FieldValidation) => {
    const setOptions: string[] = uiSchemaOptions?.exclude || ['TCS', 'Tata Consultancy Services'];
    const excludeOptionsLowerCase = setOptions.map((option: string) => option.toLowerCase());
    const lowerCaseValue = value.toLowerCase();
    if (excludeOptionsLowerCase.includes(lowerCaseValue) || lowerCaseValue === '' || lowerCaseValue === undefined || lowerCaseValue === null) {
        validation.addError('User is not allowed to raise request for getting Github Co-Pilot Access');
    }
};
