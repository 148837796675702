/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { TextField, FormHelperText } from '@material-ui/core';
import { fetchGithubUserName } from '../api';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { GITHUB_MAINTAINER_NAME, HELPER_TEXT_GITHUB_MAINTAINER_NAME} from '@internal/backstage-plugin-utils-common'

interface GithubRbacTeamMaintainerFormProps {
    githubUsername: string;
    handleGithubUsernameChange: (_: any) => void;
    githubValidationMessage: string;
    rbacError: boolean;
    setGithubValidationMessage: (message: string) => void;
    setRbacError: (error: boolean) => void;
    handleGitRBACError: (_: any) => void;
}

const GithubRbacTeamMaintainerForm: React.FC<GithubRbacTeamMaintainerFormProps> = ({
    githubUsername,
    handleGithubUsernameChange,
    githubValidationMessage,
    rbacError,
    setGithubValidationMessage,
    setRbacError,
    handleGitRBACError,

}) => {

    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);


    const fetchGitUserInfo = async (githubUser: any) => {

        try {
            const response = await fetchGithubUserName(githubUser, discoveryApi, fetchApi);


            if (response.ok && (response.status === 200 || response.status === 204)) {
                setGithubValidationMessage('Valid GitHub username');
                setRbacError(false);
                handleGitRBACError('exists');
            } else if (!githubUsername) {
                setGithubValidationMessage('');
                setRbacError(false);
            }
            else {
                setGithubValidationMessage('User not found in the specified GitHub organization');
                setRbacError(true);
                handleGitRBACError('doNotExists')
            }
        } catch (error) {
            console.error('Error parsing JSON or other fetchGitUserInfo error:', error);
            setRbacError(true);
            setGithubValidationMessage('Error validating GitHub username');
        }

    };
    useEffect(() => {
        fetchGitUserInfo(githubUsername);
    },
        [githubUsername]
    );




    return (
        <>
            <TextField
                label={GITHUB_MAINTAINER_NAME}
                value={githubUsername}
                onChange={handleGithubUsernameChange}
                fullWidth
                variant="outlined"
                margin='dense'
                error={rbacError}
                helperText={HELPER_TEXT_GITHUB_MAINTAINER_NAME}

            />
            {githubValidationMessage && <FormHelperText>{githubValidationMessage === 'Valid GitHub username' ?
                <div style={{ color: 'green' }}>{githubValidationMessage}</div> :
                <div style={{ color: 'red' }}>{githubValidationMessage}</div>}</FormHelperText>}
        </>
    );
};

export default GithubRbacTeamMaintainerForm;