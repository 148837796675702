import {
    scaffolderPlugin,
} from '@internal/backstage-plugin-scaffolder';
import {createScaffolderFieldExtension} from '@internal/backstage-plugin-scaffolder-react';
import { UpdateEcosystemBuilderV2 } from './EcosystemBuilderV2';

export const UpdateEcosystemBuilderExtensionV2 = scaffolderPlugin.provide(
createScaffolderFieldExtension({
    name: 'EcosystemBuilderUpdateV2',
    component: UpdateEcosystemBuilderV2,
}),
);
