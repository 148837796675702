import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, FormHelperText, InputAdornment, TextField } from '@mui/material';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { fetchUser } from './api';
import type { FieldValidation } from '@rjsf/utils';
import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@internal/backstage-plugin-scaffolder';

// Define the interface for user details
interface userDetails {
  userPrincipalName: string;
  accountEnabled: string;
  companyName: string;
  displayName: string;
  employeeId: string;
  mail: string;
  userType: string
}

// Global variable to track email validation status
let Email: string = '';

export const MandSEmailValidation = ({
  onChange,
  schema,
}: {
  onChange: (data: any) => void;
  schema: any;
}) => {

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [userEmail, setUserEmail] = useState<string>('');
  const [userDetails, setUserDetails] = useState<userDetails | null>(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState<boolean | null>(null);

  // Function to validate email format
  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@(mnscorp\.net)$/;
    return regex.test(email);
  };

  // To validate and fetch user details when email changes
  useEffect(() => {
    if (!userEmail) {
      setError('');
      setUserDetails(null)
      setIsValid(false);
      return;
    }
    if (isValidEmail(userEmail)) {
      setError('');
      const fetchUserData = async (email: string) => {
        setEmailLoading(true);
        try {
          const userData = await fetchUser(discoveryApi, fetchApi, email);
          if (userData && userData.accountEnabled) {
            setUserDetails(userData);
            setIsValid(true);
            Email = 'exist'
          } else {
            setUserDetails(null);
            setError('This is not a valid user email');
            Email = 'Not a valid user'
            setIsValid(false);
          }
        }
        catch (error: any) {
          console.error('Error fetching product data:', error);
          Email = 'Not a valid user'
          setUserDetails(null);
          setIsValid(false);
          setError('Error fetching user details');
        } finally {
          setEmailLoading(false);
        }
      }
      fetchUserData(userEmail);
    } else {
      Email = 'Not a valid user'
      setError('This is not a valid email format.');
      setIsValid(false);
    }
  }, [userEmail, discoveryApi, fetchApi])

  // To update parent component when user details change
  useEffect(() => {
    if (userDetails) {
      console.log("User details", userDetails);
      onChange({
        userPrincipalName: userEmail,
        // userPrincipalName: userDetails.userPrincipalName,
        mail: userDetails?.mail,
        employeeId: userDetails?.employeeId || '',
        companyName: userDetails?.companyName || '',
        displayName: userDetails?.displayName || ''
      });
    } else {
      onChange({
        userEmail,
        userPrincipalName: '',
        mail: '',
      });
    }
  }, [userDetails, userEmail, onChange]);


  // Handle email input change
  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const emailValue = event.target.value;
    setUserEmail(emailValue);
    onChange({ userEmail: emailValue });
  };

  return (
    <>
      <TextField
        label={schema.title}
        variant="outlined"
        required
        fullWidth
        value={userEmail}
        style={{ paddingBottom: '20px' }}
        onChange={handleEmailChange}
        color={isValid === true ? 'success' : isValid === false ? 'error' : 'primary'}
        focused={isValid !== null}
        helperText={schema.description}
        slotProps={{
          input: {
            endAdornment: emailLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={24} />
              </InputAdornment>
            ) : null,
          },
        }}
      // helperText={
      //   error ? (
      //     <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
      //   ) : isValid ? (
      //     <FormHelperText style={{ color: 'green' }}>Valid Email</FormHelperText>
      //   ) : (
      //     'Provide the email ID'
      //   )
      // }
      />
      {error && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
      {isValid && <FormHelperText style={{ color: 'green' }}>Valid Email</FormHelperText>}
    </>
  )
}

export const EmailValidation = (value: any, validation: FieldValidation) => {
  console.log(JSON.parse(JSON.stringify(value)).userEmail)
  if (Email === 'Not a valid user') {
    validation.addError('Invalid user email. Please check the details.');
  }
};


const UserEmailExtensionWithOptionsFieldSchema = makeFieldSchemaFromZod(
  z.object({
    UserEmailValidation: z
    .string()
    .email()
    .regex(/@mnscorp\.net$/, 'Email must be from comcat.net domain'),
  }),
);

export const UserEmailExtensionWithOptionsSchema = UserEmailExtensionWithOptionsFieldSchema.schema;

