import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  ServiceAccountsCreationLimiter,
  ServiceAccountValidation,
} from './ServiceAccountCreationLimiterExtension';

export const ConfluentCloudServiceAccountAccountLimiterFieldExtension =
  scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'cc-list-serviceaccounts-creation-limiter',
      component: ServiceAccountsCreationLimiter,
      validation: ServiceAccountValidation,
    }),
  );