import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  BannerForUserRestriction,
  BannerForUserRestrictionValidation,
} from './BannerForCopilotUsers';

export const BannerForUserRestrictionForCoPilot = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'BannerForUserRestriction',
    component: BannerForUserRestriction,
    validation: BannerForUserRestrictionValidation,
  }),
);
