import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  AzureActiveDirectory,
  AzureActiveDirectoryValidation,
} from './AzureActiveDirectoryExtension';
import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';

export const AzureActiveDirectoryFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureActiveDirectory',
    component: AzureActiveDirectory,
    validation: AzureActiveDirectoryValidation,
  }),
);
