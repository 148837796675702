import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  AzureSubscriptionExtensionWithOptionsSchema,
  AzureSubscriptionExtension,
} from './AzureSubscriptionExtension';

export const AzureSubscriptionFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureSubscription',
    component: AzureSubscriptionExtension,
    schema: AzureSubscriptionExtensionWithOptionsSchema,
  }),
);
