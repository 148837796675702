import React, { useState, useEffect } from 'react';
import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import { fetchIocode } from './api';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { FormHelperText } from '@material-ui/core';

export const IocodeValidator = ({
    onChange,
    schema,
    idSchema,
}: any) => {
    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);

    const [ioCodeValue, setIoCodeValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [iocodeError, setIocodeError] = useState<boolean | null>(null);

    useEffect(() => {
        const validateIocode = async () => {
            if (!ioCodeValue) {
                setIocodeError(null); // Reset validation when input is cleared
                return;
            }
            setLoading(true);
            try {
                const data = await fetchIocode(ioCodeValue, discoveryApi, fetchApi);
                if (data.value.value[0].fields.field_1 === ioCodeValue) {  // Check response status and match the value
                    setIocodeError(false);
                    // onChange({ iocode: ioCodeValue }); // Pass the valid input to the onChange handler
                } else {
                    setIocodeError(true);
                }
            } catch (error: any) {
                console.error('Error fetching iocodes:', error);
                setIocodeError(true);
            } finally {
                setLoading(false);
            }
        };

        validateIocode(); // Trigger the API call whenever inputValue changes
    }, [ioCodeValue, discoveryApi, fetchApi]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setIoCodeValue(value);
        onChange({ iocode: value || '' });
    }
    return (
        <>
            <TextField
                fullWidth
                id={idSchema?.$id}
                label={schema.title}
                value={ioCodeValue}
                onChange={handleInputChange}
                helperText={schema.description}
                error={iocodeError === true}
                InputProps={{
                    endAdornment: loading ? (
                        <CircularProgress size={24} />
                    ) : null
                }}
            />
            {iocodeError && (
                <Alert severity="error" style={{ marginTop: 10 }}>
                    Invalid IO Code
                </Alert>
            )}
            {!iocodeError && !loading && ioCodeValue && (
                <FormHelperText style={{ color: 'green' }} >
                    Valid IO Code
                </FormHelperText>
            )}
        </>
    );
};
