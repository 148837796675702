import React from 'react';
import { Checkbox, TextField, Grid, Dialog, DialogContent, DialogTitle, Chip, TextFieldProps, Tooltip, IconButton } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import {
    CREATE_PROD_RG,
    CREATE_NONPROD_RG,
    HELPER_TEXT_PROD_RG,
    HELPER_TEXT_NONPROD_RG,
} from '@internal/backstage-plugin-utils-common'



interface CreateRgFormProps {
    open: boolean;
    onClose: () => void;
    selectedProdEnvironments: string[];
    selectedNonProdEnvironments: string[];
    handleProdEnvChange: (_: any, newValue: any) => void;
    handleNonProdEnvChange: (_: any, newValue: any) => void;
    setRgCheckboxChecked: (isChecked: boolean) => void;
    setOpenDialog: (isOpen: boolean) => void;
    rgCheckboxChecked: boolean;
}

const CreateRgForm: React.FC<CreateRgFormProps> = ({
    open,
    onClose,
    selectedProdEnvironments,
    selectedNonProdEnvironments,
    handleProdEnvChange,
    handleNonProdEnvChange,
    setRgCheckboxChecked,
    setOpenDialog,
    rgCheckboxChecked
}) => {
    const productionEnvironments = ["Production", "Disaster Recovery"];
    const nonProductionEnvironments = ["Development", "System Integration Testing", "User Acceptance Testing", "CATE", "Components Integration Testing", "Operational Acceptance Testing", "Performance Load Testing", "Pre Production", "None"];

    return (
        <>
            <Dialog open={open}
                fullWidth maxWidth="md">
                <DialogTitle>
                    Environment Selection
                    <Tooltip title="Close">
                        <IconButton aria-label="close" onClick={() => {

                            if (selectedProdEnvironments.length === 0 && selectedNonProdEnvironments.length === 0) {
                                setRgCheckboxChecked(false);
                            }
                            onClose();
                        }} style={{ position: 'absolute', right: 8, top: 8 }}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent style={{ height: '180px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="production-environments"
                                options={productionEnvironments}
                                value={selectedProdEnvironments}
                                onChange={handleProdEnvChange}
                                color='primary'
                                renderOption={(option: any, { selected }: any) => (
                                    <>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option}
                                    </>
                                )}
                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                    <TextField {...params} variant="outlined" label={CREATE_PROD_RG} margin='dense' helperText={HELPER_TEXT_PROD_RG} />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                id="non-production-environments"
                                options={nonProductionEnvironments}
                                value={selectedNonProdEnvironments}
                                onChange={handleNonProdEnvChange}
                                color='primary'
                                renderOption={(option: any, { selected }: any) => (
                                    <>
                                        <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                        {option}
                                    </>
                                )}
                                renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                    <TextField {...params} variant="outlined" label={CREATE_NONPROD_RG} margin='dense' helperText={HELPER_TEXT_NONPROD_RG} />
                                )}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            {rgCheckboxChecked && (selectedProdEnvironments.length > 0 || selectedNonProdEnvironments.length > 0) && (
                <div>

                    {rgCheckboxChecked && selectedProdEnvironments.length > 0 && (
                        <>
                            <Tooltip title='Click Here to Modify Production List'>
                                <Chip color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setRgCheckboxChecked(true);
                                        setOpenDialog(true);
                                    }}
                                    label={`Selected Prod: ${selectedProdEnvironments.join(', ')}`} />
                            </Tooltip>
                        </>
                    )}
                    {rgCheckboxChecked && selectedNonProdEnvironments.length > 0 && (
                        <>
                            <Tooltip title='Click Here to Modify Non-Production List'>
                                <Chip color="primary"
                                    variant="outlined"
                                    onClick={() => {
                                        setRgCheckboxChecked(true);
                                        setOpenDialog(true);
                                    }}
                                    label={`Selected Non-Prod: ${selectedNonProdEnvironments.join(', ')}`} />
                            </Tooltip>
                        </>

                    )}
                </div>
            )}
        </>
    );
};

export default CreateRgForm;