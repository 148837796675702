import React from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import { TextField, makeStyles } from '@material-ui/core';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsync } from 'react-use';
import { findValueByKey } from '@internal/backstage-plugin-utils-common';

let uiSchemaOptions: any;
let GlobalValidation: string;

export const AzureADgroupExtension = ({
  onChange,
  required,
  rawErrors,
  uiSchema,
  schema,
  formContext,
}: any) => {
  const [groupExists, setGroupExists] = React.useState(false);
  const [groupChecked, setGroupChecked] = React.useState(false);
  const [groupName, setGroupName] = React.useState('');
  uiSchemaOptions = uiSchema['ui:options'];
  const allowedForm = uiSchemaOptions?.form || [''];
  const allowedDelimiter = uiSchemaOptions?.delimiter
    ? uiSchemaOptions?.delimiter
    : ' ';
  const useDisable = uiSchemaOptions?.disable
    ? uiSchemaOptions?.disable
    : false;
  const useStyles = makeStyles({
    groupExists: {
      color: 'red',
    },
    groupNotExists: {
      color: 'green',
    },
  });

  const FormArrayToConcate: string[] = allowedForm;
  allowedForm?.map((eachFormItem: string) => {
    if (eachFormItem?.includes('${{')) {
      const userindex = allowedForm?.indexOf(eachFormItem);
      const formField = eachFormItem
        .substring(3, eachFormItem.length - 2)
        .replace('parameters.', '')
        .trim();
      const fieldValue = findValueByKey(
        JSON.parse(JSON.stringify(formContext.formData)),
        formField.split('.')[formField.split('.').length - 1],
      );
      FormArrayToConcate[userindex] = fieldValue;
    }
  });

  const FormArrayValue = allowedForm.join(allowedDelimiter);

  React.useEffect(() => {
    setGroupName(FormArrayValue);
    onChange(FormArrayValue);
    handleInputBlur();
  }, [FormArrayValue]);

  const apiRef = useApi(microsoftAuthApiRef);
  const { value: token } = useAsync(() => apiRef.getAccessToken());

  async function checkAzureADGroup(groupName: string) {
    try {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/groups?$search=%22displayName%3a${groupName}%22&$select=id,displayName`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            ConsistencyLevel: 'eventual',
          },
          redirect: 'follow',
        },
      );

      const data = await response.json();
      const groups: { displayName: string }[] = data.value;

      // const exists = groups?.length > 0;

      const exists = groups.some(group => group.displayName === groupName);
      if (exists) {
        console.log('Group exists:', groupName);
        GlobalValidation = 'exists';
        return true;
      } else {
        console.log('Group does not exist:', groupName);
        GlobalValidation = 'doNotExists';
        return false;
      }
    } catch (error) {
      console.error('Error checking Azure AD group:', error);
      return false;
    }
  }

  // Validate based on each keypress from the user
  const handleInputChange = async (
    _event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    if (value.trim() === '') {
      setGroupName('');
      setGroupChecked(false);
      GlobalValidation = 'empty';
      onChange('');
      return;
    }
    onChange(value);
    setGroupName(value);
    setGroupChecked(false);

    try {
      const exists = await checkAzureADGroup(value);
      setGroupExists(exists);
      setGroupChecked(true);
    } catch (error) {
      console.error('Not able to return AD Group');
    }
  };

  // validation based on clicking outside the text box
  const handleInputBlur = async () => {
    if (groupName.trim() === '') {
      setGroupChecked(false);
      return;
    }
    try {
      const exists = await checkAzureADGroup(groupName);
      setGroupExists(exists);
      setGroupChecked(true);
    } catch (error) {
      console.error('Not able to return AD Group');
    }
  };

  let widthADgroup = '100%';
  const classes = useStyles();

  // Helper text for existing group name
  const existingGroupHelperText = groupExists
    ? 'An AD group already exists with the same name. Please try another name.'
    : '';

  // Helper text for available group name
  const availableGroupHelperText =
    groupChecked && !groupExists ? 'The name is available for use.' : '';

  return (
    <>
      <FormControl
        margin="normal"
        style={{ flexDirection: 'row' }}
        required={required}
        error={rawErrors?.length > 0}
      >
        <TextField
          value={groupName}
          label={schema.title}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleInputChange(event, event.target.value)
          }
          onBlur={handleInputBlur}
          className={groupName ? classes.groupExists : classes.groupNotExists}
          style={{ width: widthADgroup }}
          variant="outlined"
          error={groupExists ? true : false}
          color="primary"
          helperText={
            groupExists
              ? groupExists && (
                  <div style={{ color: 'red' }}>{existingGroupHelperText}</div>
                )
              : groupChecked &&
                !groupExists && (
                  <div style={{ color: 'green' }}>
                    {availableGroupHelperText}
                  </div>
                )
          }
          disabled={useDisable ? true : false}
        />
      </FormControl>
    </>
  );
};

export const ADvalidation = (value: string, validation: FieldValidation) => {
  const regexExpression = uiSchemaOptions?.regexExp;
  if (regexExpression) {
    const regex = new RegExp(regexExpression);
    const isValid = regex.test(value);
    if (
      !isValid &&
      GlobalValidation !== 'empty' &&
      GlobalValidation !== 'exists'
    ) {
      GlobalValidation = 'Regex mis-match';
    }
  }
  if (uiSchemaOptions?.validate) {
    if (
      GlobalValidation == 'exists' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.form
    ) {
      validation.addError('');
    } else if (
      GlobalValidation == 'empty' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.form
    ) {
      validation.addError('This is a mandatory Field');
    } else if (
      GlobalValidation == 'Regex mis-match' &&
      uiSchemaOptions.validate &&
      uiSchemaOptions.regexExp
    ) {
      validation.addError(
        'The expression is not matching with the Regex Expression',
      );
    } else if (GlobalValidation == 'exists') {
      validation.addError('');
    } else if (GlobalValidation == 'empty') {
      validation.addError('This is a mandatory Field');
    }
  } else {
    if (GlobalValidation == 'exists' && uiSchemaOptions.form) {
      validation.addError('');
    } else if (GlobalValidation == 'empty' && uiSchemaOptions.form) {
      validation.addError('This is a mandatory Field');
    } else if (
      GlobalValidation == 'Regex mis-match' &&
      uiSchemaOptions.regexExp
    ) {
      validation.addError(
        'The expression is not matching with the Regex Expression',
      );
    } else if (GlobalValidation == 'exists') {
      validation.addError('');
    } else if (GlobalValidation == 'empty') {
      validation.addError('This is a mandatory Field');
    }
  }
};
