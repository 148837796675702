import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';
import { TechDocsTabContent } from '../TabContent/TechDocTabContent';
import { CICDTabContent } from '../TabContent/CICDTabContent';
import { OverviewTabContent } from '../TabContent/OverViewTabContent';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import { IntegrationTabContent } from '../TabContent/IntegrationTabContent';
import { CodeHealthTabContent } from '../TabContent/CodeHealthTabContent';
import {
  isNewRelicDashboardAvailable,
  EntityNewRelicDashboardContent,
} from '@backstage-community/plugin-newrelic-dashboard';

import {
  isPluginApplicableToEntity as isPagerDutyAvailable,
  EntityPagerDutyCard,
} from '@pagerduty/backstage-plugin';
import { Grid } from '@material-ui/core';

export const ServiceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Documentation">
      {TechDocsTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="Devops">
      {CICDTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/integrations" title="Integrations">
      {IntegrationTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-health" title="Code Health">
      {CodeHealthTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isNewRelicDashboardAvailable}
      path="/newrelic-dashboard"
      title="New Relic Dashboard"
    >
      <EntityNewRelicDashboardContent />
    </EntityLayout.Route>

    <EntityLayout.Route
      if={isPagerDutyAvailable}
      path="/service-management"
      title="Service Management"
    >
      <Grid item md={6}>
        <EntityPagerDutyCard />
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);
