/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import type { FieldValidation } from '@rjsf/utils';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { makeFieldSchemaFromZod } from '@internal/backstage-plugin-scaffolder';
import { z } from 'zod';

export const SignedInUserEmailExtension = ({
  onChange,
  schema = {
    title: 'Email',
    description: 'The signed in user',
  },
  required,
  rawErrors,
  uiSchema,
}: any) => {
  const [userEmail, setUserEmail] = useState<string>();
  const identityApi = useApi(identityApiRef);

  useEffect(() => {
    if (userEmail === undefined || userEmail === '') {
      identityApi.getProfileInfo().then(profile => {
        onChange(`${(profile.email as string).split('@')[0]}@mnscorp.net`);
        setUserEmail(`${(profile.email as string).split('@')[0]}@mnscorp.net`);
      });
    }
  }, [identityApi]);

  const setNewEmailvalue = (event: { target: { value: string } }) => {
    onChange(event.target.value);
    setUserEmail(event.target.value);
  };

  return (
    <FormControl
      margin="normal"
      style={{ flexDirection: 'row' }}
      required={required}
      error={rawErrors?.length > 0}
    >
      {uiSchema['ui:options']?.hide === true ? (
        <></>
      ) : (
        <TextField
          hidden
          label={schema.title}
          value={userEmail}
          onChange={setNewEmailvalue}
          InputProps={{
            readOnly: uiSchema['ui:options']?.readOnly as boolean,
          }}
          fullWidth
          helperText={schema.description}
        />
      )}
    </FormControl>
  );
};

export const validateSignedInUserEmailField = (
  value: string,
  validation: FieldValidation,
) => {
  const kebabCase = /^[a-zA-Z0-9._%+-]+@mnscorp\.net$/g.test(value);
  if (kebabCase === false) {
    validation.addError(`Email must be in the format of name.name@mnscorp.net`);
  }
};

export const SignedInUserEmailExtensionWithOptionsSchema =
  makeFieldSchemaFromZod(
    z.string(),
    z.object({
      readOnly: z
        .boolean()
        .optional()
        .describe(
          'Allow user to edit the Signed In user Form Field . Defaults to false',
        ),
      hide: z
        .boolean()
        .optional()
        .describe(
          'Whether to display the Signed In user Field on the form. Defaults to true',
        ),
    }),
  ).schema;
