import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import {
  Azureaadgrpemail,
  AzureaadgrpemailValidation,
  AzureaadgrpemailValidationSchema,
} from './azureaadgrpemail';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';

export const ValidateAzureAADGrpEmailFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'validate-azure-aad-grp-email',
    component: Azureaadgrpemail,
    validation: AzureaadgrpemailValidation,
    schema: AzureaadgrpemailValidationSchema,
  }),
);
