import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  ProductPicker,
} from './ProductStructureExtension';

export const ProductStructureListExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Product',
    component: ProductPicker,
  }),
);
