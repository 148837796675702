/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  alertApiRef,
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { Tooltip } from '@material-ui/core';

const drawerWidth = '410px';

const useStyles = makeStyles(_theme =>
  createStyles({
    Sidebar__Button: {
      transform: 'rotate(-90deg) translateY(61px)',
      borderRadius: '8px 8px 0 0',
      backgroundColor: '#02C3BD',
      '&:hover': {
        backgroundColor: '#F49F0A',
        color: '#00283D',
      },
      color: '#00283D',
      outline: '0',
      border: '0',
      fontFamily: 'Helvetica Neue,sans-serif',
      maxHeight: '100px',
      maxWidth: '200px',
      fontSize: '12.75px',
      cursor: 'pointer',
      top: '50%',
      position: 'sticky',
      display: 'inline-block',
      verticalAlign: 'middle',
      float: 'right',
      marginTop: '-5%',
      zIndex: 5000,
      textAlign: 'center',
      fontWeight: 'bolder',
    },
    Sidebar__Button__text: {
      display: 'inline-block',
      align: 'center',
      verticalAlign: 'middle',
      fontSize: '12.75px',
    },
    drawer: {
      right: '0',
      display: 'flex',
      overflow: 'auto',
    },
    drawerPaper: {
      position: 'absolute',
      display: 'flex',
      width: drawerWidth,
      height: '710px',
      top: '111px !important',
      overflowY: 'hidden',
      borderRadius: '9px 0px 0px 9px',
      transition: 'all 250ms ease-in-out',
      background: 'linear-gradient(rgb(2,195,189), #FFFFFF 30%);',
      zIndex: 4000,
    },
    drawerContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 10px 0px 9px',
      color: '#00283D !important',
    },
    backdrop: {
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    stickyButton: {
      position: 'absolute',
      height: '40px !important',
      display: 'flex',
      border: 'none',
      cursor: 'pointer',
      top: '450px !important',
      right: '335px !important',
      transform: 'rotate(-90deg) translateY(-50%)',
      zIndex: 1300,
      fontWeight: 'bold',
      padding: '5px 15px 10px 15px !important',
    },
    navigation: {
      width: '100%',
      top: '100%',
      position: 'sticky',
      bottom: 0,
      backgroundColor: 'rgb(2,195,189)',
      borderTop: '1px solid #E5E5E5',
      zIndex: 2000,
      color: '#FFFFFF !important',
      justifyContent: 'space-around',
    },
    iconStyle: {
      marginTop: '6px',
      fontSize: '30px',
      color: '#FFFFFF !important',
      label: {
        fontSize: '1rem',
        color: '#FFFFFF !important',
      },
    },
    buttonStyle: {
      textDecoration: 'none',
      color: 'inherit',
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '6px 0',
    },
    clickableBox: {
      border: '1px solid #7593AF',
      position: 'relative',
      padding: '6px',
      borderRadius: '8px',
      marginBottom: '6px',
      textAlign: 'left',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#00283D',
    },
    boxIcon: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      marginRight: '10px',
      fontSize: '24px',
    },
    boxText: {
      textAlign: 'left',
    },
  }),
);

export function RenderSupportButtonDrawer() {
  if (localStorage.getItem("theme") === null) {
    localStorage.setItem("theme", "light");
  }
  const isMobile = window.screen.width < 1000;
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(alertApiRef);
  const configApi = useApi(configApiRef);
  const teamsUrl = configApi.getString('supportChannel.teamsLink');
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [stickyButton, setStickyButton] = useState(false);
  const [selectTab, setSelectTab] = useState('0');

  const { value: profile, error } = useAsync(() =>
    identityApi.getProfileInfo(),
  );

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: `Failed to load user identity: ${error}`,
        severity: 'error',
      });
    }
  }, [error, alertApi]);
  const displayName = profile?.displayName;
  let nameStr = '';

  if (displayName) {
    nameStr = `${displayName.includes(',')
      ? displayName.split(', ')[1]
      : displayName.split(' ')[0]
      }`;
  }

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: any) => {
    setSelectTab(newValue);
  };

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(open);
    setStickyButton(!stickyButton);
  };

  const handleButtonClick = (path: any) => {
    window.open(path, '_blank');
  };

  return (
    <>
      {!isMobile && (
        <>
          {openDrawer && (
            <div className={classes.backdrop} onClick={toggleDrawer(false)} />
          )}
          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={toggleDrawer(false)}
            classes={{
              paper: classes.drawerPaper,
            }}
            className={classes.drawer}
          >
            <div className={classes.drawerContent}>
              <Typography
                variant="h2"
                component="h1"
                style={{ marginBottom: '20px' }}
              >
                Cloud9 Platforms
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                style={{ marginBottom: '40px' }}
              >
                Hi {nameStr}!
                <br />
                <Typography variant="h5" component="h2">
                  How can we help you?
                </Typography>
              </Typography>
              <div
                className={classes.clickableBox}
                onClick={() => handleButtonClick('/create')}
              >
                <div className={classes.boxText}>
                  <Typography variant="h6" component="h2">
                    Access Cloud9 self service
                    <NavigateNextIcon className={classes.boxIcon} />
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: '#7593AF' }}>
                    Use Muziris automation for access to tools & platforms and
                    jumpstart your Cloud9 onboarding
                  </Typography>
                </div>
              </div>
              <div
                className={classes.clickableBox}
                onClick={() =>
                  handleButtonClick('/create/templates/default/incident-flow')
                }
              >
                <div className={classes.boxText}>
                  <Typography variant="h6" component="h2">
                    Log an incident
                    <NavigateNextIcon className={classes.boxIcon} />
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: '#7593BA' }}>
                    Click here to raise a call with Cloud Ops for problems in
                    Azure & GCP production applications
                  </Typography>
                </div>
              </div>
              <div
                className={classes.clickableBox}
                onClick={() =>
                  handleButtonClick('/create/templates/default/workorder-flow')
                }
              >
                <div className={classes.boxText}>
                  <Typography variant="h6" component="h2">
                    Raise a request
                    <NavigateNextIcon className={classes.boxIcon} />
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: '#7593BA' }}>
                    For non-standard, one-off on non-prod requests in Cloud9
                  </Typography>
                </div>
              </div>
              <div
                className={classes.clickableBox}
                onClick={() =>
                  handleButtonClick('/docs/default/Component/Cloud9/support')
                }
              >
                <div className={classes.boxText}>
                  <Typography variant="h6" component="h2">
                    Anything Else?
                    <NavigateNextIcon className={classes.boxIcon} />
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: '#7593BA' }}>
                    To read more about support services offered, visit our
                    documentation.
                  </Typography>
                </div>
              </div>
            </div>
            <BottomNavigation
              value={selectTab}
              onChange={handleTabChange}
              showLabels
              className={classes.navigation}
            >
              <Tooltip title="Go to Muziris Home Page">
                <Button
                  className={classes.buttonStyle}
                  onClick={() => handleButtonClick('/')}
                >
                  <BottomNavigationAction
                    label="Home"
                    value="/"
                    icon={<HomeOutlinedIcon className={classes.iconStyle} />}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Search in Muziris">
                <Button
                  className={classes.buttonStyle}
                  onClick={() => handleButtonClick('/search')}
                >
                  <BottomNavigationAction
                    label="Search"
                    value="/search"
                    icon={<SearchIcon className={classes.iconStyle} />}
                  />
                </Button>
              </Tooltip>
              <Tooltip title="Connect to Cloud 9 Engineering Teams">
                <Button
                  className={classes.buttonStyle}
                  onClick={() => handleButtonClick(`${teamsUrl}`)}
                >
                  <BottomNavigationAction
                    label="Contact Support"
                    value="/docs/default/Component/Cloud9/support"
                    icon={<ContactSupportIcon className={classes.iconStyle} />}
                  />
                </Button>
              </Tooltip>
            </BottomNavigation>
          </Drawer>
          {openDrawer ? (
            ''
          ) : (
            <Button
              className={`${classes.Sidebar__Button} 
                    }`}
              variant="contained"
              disableElevation
              onClick={toggleDrawer(!openDrawer)}
            >
              <span className={classes.Sidebar__Button__text}>
                Contact Support
              </span>
            </Button>
          )}
        </>
      )}
    </>
  );
}