import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  ApplicationIdField
} from './ApplicationIdField';

export const ApplicationFieldCustomExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Application',
    component: ApplicationIdField,
  }),
);
