import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";

export async function fetchMal(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const response = await fetchApi.fetch(`${baseUrl}/v2/sharepoint/MAL/listAppsInfo`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();

        return data;


    }
    catch (error: any) {
        return(`Error: ${error.message}`);
    }
}