import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'worklist',
});

export const buildRouteRef = createSubRouteRef({
  id: 'worklist/build',
  path: '/:taskId',
  parent: rootRouteRef,
});