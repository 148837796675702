import { useApi, discoveryApiRef, fetchApiRef } from '@backstage/core-plugin-api';
import React, { useEffect, useState } from 'react';
import { fetchPdt } from './api';
import { Grid, TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface ProductPickerOptions {
    ProductID: string;
    productName: string;
    productGroup: string;
    productPortfolio: string;
    businessPlatform: string;
    Description: string;
    owner: string;
    // Aliases of fields to handle deviations in form
    id: string;
    productDescription: string;
    ownerEmail: string;
}

interface UiSchema {
    'ui:options'?: {
        show?: string[];
        mandate?: string[];
        override?: string[];
        selector?: 'productName';
    }
}

export const ProductPicker = ({ onChange, uiSchema }: {
    onChange: (data: any) => void;
    uiSchema: UiSchema;
}) => {
    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);

    const [productList, setProductList] = useState<ProductPickerOptions[]>([]);
    const [selected, setSelected] = React.useState<ProductPickerOptions | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const data = await fetchPdt(discoveryApi, fetchApi);
                setProductList(data);
            } catch (error: any) {
                throw new Error('Error fetching productss:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProducts();
    }, [discoveryApi, fetchApi]);

    const handleSelectionChange = (value: ProductPickerOptions | null) => {
        setSelected(value);
        if (value) {
            onChange({
                id: value?.ProductID,
                productName: value?.productName,
                productDescription: value?.Description || '',
                productGroup: value?.productGroup || '',
                productPortfolio: value?.productPortfolio || '',
                businessPlatform: value?.businessPlatform || '',
                ownerEmail: value?.owner || ''
            });
        } else {
            onChange(null);
        }
    };

    const getFieldValue = (field: string, product: ProductPickerOptions | null): string => {
        if (!product) return '';
        switch (field) {
            case 'id':
                return product.ProductID || '';
            case 'productDescription':
                return product.Description || '';
            case 'ownerEmail':
                return product.owner || '';
            default:
                return product[field as keyof ProductPickerOptions] || '';
        }
    };

    const updateFieldValue = (field: string, value: string, product: ProductPickerOptions | null): ProductPickerOptions => {
        if (!product) return {} as ProductPickerOptions;

        const updatedProduct = { ...product };

        switch (field) {
            case 'id':
                updatedProduct.ProductID = value;
                break;
            case 'productDescription':
                updatedProduct.Description = value;
                break;
            case 'ownerEmail':
                updatedProduct.owner = value;
                break;
            default:
                updatedProduct[field as keyof ProductPickerOptions] = value;
                break;
        }
        return updatedProduct;
    };


    const shouldShowProductName = uiSchema['ui:options']?.selector === 'productName' || !uiSchema['ui:options']?.selector;

    const renderFields = () => {
        return (
            <Grid container direction="column" spacing={3}>
                {/* Show Fields (Non-Editable) */}
                {uiSchema['ui:options']?.show?.map((field: string) => {
                    const value = getFieldValue(field,selected);
                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                fullWidth
                                value={value || 'NA'}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    )
                })}

                {/* Mandate Fields (Mandatory) */}
                {uiSchema['ui:options']?.mandate?.map((field: string) => {
                    const value = getFieldValue(field,selected);
                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                required
                                fullWidth
                                value={value}
                                onChange={(e) => {
                                    const updatedproducts = updateFieldValue(field, e.target.value,selected);
                                    handleSelectionChange(updatedproducts);
                                }}
                                variant="outlined"
                                error={!selected?.[field as keyof ProductPickerOptions]}
                                helperText={
                                    !selected?.[field as keyof ProductPickerOptions]
                                        ? `${field} is required.`
                                        : ''
                                }
                            />
                        </Grid>
                    )
                })}

                {/* Override Fields (Editable, Overrides API Values) */}
                {uiSchema['ui:options']?.override?.map((field: string) => {
                    const value = getFieldValue(field,selected);

                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                fullWidth
                                size="medium"
                                value={value}
                                onChange={(e) => {
                                    const updatedproducts = updateFieldValue(field, e.target.value, selected);
                                    handleSelectionChange(updatedproducts);
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };


    return (
        <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
                {shouldShowProductName && (
                    <Autocomplete
                        options={productList}
                        loading={loading}
                        getOptionLabel={(option) => option.productName || ''}
                        value={selected}
                        onChange={(_, value) => handleSelectionChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Product Name"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            </Grid>
            {renderFields()}
        </Grid>
    )
}