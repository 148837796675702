/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';

/** @public */
export const GITHUB_ACTIONS_ANNOTATION = "github.com/project-slug";
export const MNSCORP_CICD_ANNOTATION = "mnscorp.net/cicd-as-service";
export const MNSCORP_DEV_CICD_ANNOTATION = "mnscorp.net/dev-cicd-as-service";

export const getProjectNameFromEntity = (entity: Entity) =>
  entity?.metadata.annotations?.[GITHUB_ACTIONS_ANNOTATION] ?? "";

export const getCICDProjectNameFromEntity = (entity: Entity) =>
  entity?.metadata.annotations?.[MNSCORP_CICD_ANNOTATION] ?? "";

export const getDevCICDProjectNameFromEntity = (entity: Entity) =>
  entity?.metadata.annotations?.[MNSCORP_DEV_CICD_ANNOTATION] ?? "";
