import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import { GitHubRepositoryField } from './GitHubRepositoryExtension';

export const GitHubRepositoryExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'github-repo-dropdown',
    component: GitHubRepositoryField,
  }),
);
