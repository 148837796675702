import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import { CreateEcosystemBuilderV2, validateEcosystemBuilderV2 } from './EcosystemBuilderV2Creation';

export const CreateEcosystemBuilderV2Form = scaffolderPlugin.provide(
createScaffolderFieldExtension({
    name: 'createEcosystemBuilderV2',
    component: CreateEcosystemBuilderV2,
    validation: validateEcosystemBuilderV2,
}),
);
