/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-else-return */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { Entity } from '@backstage/catalog-model';
import {
  useEntity,
  MissingAnnotationEmptyState,
} from '@backstage/plugin-catalog-react';
import { Routes, Route } from 'react-router-dom';
import { buildRouteRef } from '../routes';
import { WorkflowRunDetails } from './WorkflowRunDetails';
import { WorkflowRunsCard } from './WorkflowRunsCard';
import { WorkflowRunsTable } from './WorkflowRunsTable';
import { getCICDProjectNameFromEntity, getDevCICDProjectNameFromEntity, getProjectNameFromEntity, GITHUB_ACTIONS_ANNOTATION, MNSCORP_CICD_ANNOTATION, MNSCORP_DEV_CICD_ANNOTATION } from './getProjectNameFromEntity';
import { RouterProps } from '../api/types';
import { FilteredWorkflowRunsTable } from './WorkflowRunsTable/WorkflowRunsTable';
import { Grid, Button } from '@material-ui/core';

/** @public */
export const isGithubActionsAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[GITHUB_ACTIONS_ANNOTATION]);
export const isGithubActionsServiceAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[MNSCORP_CICD_ANNOTATION]);

export const isDevGithubActionsServiceAvailable = (entity: Entity) =>
  Boolean(entity.metadata.annotations?.[MNSCORP_DEV_CICD_ANNOTATION]);

/** @public */
export const Router = (props: RouterProps) => {
  const { view = 'table' } = props;
  const { entity } = useEntity();
  let projectTitle = getProjectNameFromEntity(entity);
  if (isGithubActionsServiceAvailable(entity)) {
    projectTitle = `Custom pipeline for ${(projectTitle ?? '/').split('/')[1]}`;

  }

  if (!isGithubActionsAvailable(entity)) {
    return (
      <MissingAnnotationEmptyState annotation={GITHUB_ACTIONS_ANNOTATION} />
    );
  }

  const workflowRunsComponent =
    view === 'cards' ? (
      <WorkflowRunsCard projectTitle={projectTitle} entity={entity} />
    ) : (
      <WorkflowRunsTable projectTitle={projectTitle} entity={entity} />
    );

  return (
    <Routes>
      <Route path="/" element={workflowRunsComponent} />
      <Route
        path={`${buildRouteRef.path}`}
        element={<WorkflowRunDetails entity={entity} />}
      />
    </Routes>
  );
};

/** @public */
export const GithubActionRouter = () => {
  const { entity } = useEntity();
  if (Object.keys(entity?.metadata?.annotations as Record<string, string>).filter(item => item.includes("cicd-as-service")).length === 0) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={1}> <img style={{ width: '100%', alignContent: 'right' }} src="/img/giphy.webp" alt="NEW" /></Grid>
          <Grid item xs={8}>
            <h1 style={{ lineHeight: 1.334, fontWeight: 700, fontSize: '1.5rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }} >Cloud9 CICD as a Service</h1>
            <p style={{ lineHeight: 1.5, fontWeight: 400, fontSize: '1rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }}>Cloud9 CICD as a service is generally available now. Click to enroll this project.</p>
          </Grid>

          <Grid item xs={3}>
            <p />
            <p />
            <Button
              variant="contained"
              color="primary"
              href="/create/templates/default/cicdaas-pipeline-ui-dev"
            >
              Enroll Now
            </Button>
          </Grid>
        </Grid>

      </>
    );
  } else {
    let { entity } = useEntity();
    const repo = (entity.metadata.annotations?.[GITHUB_ACTIONS_ANNOTATION] ?? '/').split('/')[1];
    const entityObj = getCICDEntity(entity);
    if (entityObj === undefined) {
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={1}><p /><p /> <p /><p /><img style={{ width: '100%', alignContent: 'right' }} src="https://icon-library.com/images/error-icon-transparent/error-icon-transparent-21.jpg" alt="NEW" /></Grid>
            <Grid item xs={8}>
              <h1 style={{ lineHeight: 1.334, fontWeight: 700, fontSize: '1.5rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }} >Cloud9 CICD as a Service</h1>
              <p style={{ lineHeight: 1.5, fontWeight: 400, fontSize: '1rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }}> Both DEV and PROD annotations are found.</p>
            </Grid>

            <Grid item xs={3}>
              <p />
              <p />
              <Button
                variant="contained"
                color="primary"
                href="https://teams.microsoft.com/l/channel/19%3adnlQpvbo7zsPTGQVuXaRYH1UjDRPnOCJC7XI7064u6w1%40thread.tacv2/General?groupId=9db482de-c01a-4efb-9c4f-6693ad31c189&tenantId=bd5c6713-7399-4b31-be79-78f2d078e543"
              >
                Contact Support
              </Button>
            </Grid>
          </Grid>
        </>
      );
    }
    entity = {
      apiVersion: 'v1',
      kind: 'Component',
      metadata: {
        name: 'software',
        annotations: {
          'github.com/project-slug': entityObj?.enityName as string
        },
      },
      spec: {
        owner: 'guest',
        type: 'service',
        lifecycle: 'production',
      },
    }
    return (
      <Routes>
        <Route path="/" element={<div id="todo-highlight-user-using-dev"><FilteredWorkflowRunsTable projectTitle="Cloud9 CICD as a Service pipeline executions" entity={entity} productTeamRepoName={repo} /></div>} />
        <Route
          path={`${buildRouteRef.path}`}
          element={<WorkflowRunDetails entity={entity} />}
        />
      </Routes>
    );
  }
};


function getCICDEntity(entity: Entity) {
  if (Object.keys(entity?.metadata?.annotations as Record<string, string>).filter(item => item.includes("cicd-as-service")).length >= 2) {
    return undefined;
  } else if (Object.keys(entity?.metadata?.annotations as Record<string, string>).filter(item => item.includes("cicd-as-service"))[0] === MNSCORP_DEV_CICD_ANNOTATION) {
    return { enityName: getDevCICDProjectNameFromEntity(entity), isDev: true };
  } else {
    return { enityName: getCICDProjectNameFromEntity(entity), isDev: false };
  }

}
