import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import dayjs from 'dayjs';

import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@internal/backstage-plugin-scaffolder';

export const TimeStampExtension = ({ onChange, uiSchema }: any) => {
  const localizedFormat = require('dayjs/plugin/localizedFormat');
  dayjs.extend(localizedFormat);
  const [dateFormat, setDateFormat] = React.useState<string | undefined>();
  const [isSetDateFormatExecuted, setDateFormatExecuted] =
    React.useState(false);

  if (dateFormat === undefined && !isSetDateFormatExecuted) {
    setDateFormatExecuted(true);
    const usersDataFormat: any = uiSchema['ui:options']?.format;

    if (usersDataFormat !== undefined) {
      setDateFormat(usersDataFormat);
    } else {
      setDateFormat('DD-MM-YYYY hh:mm:ss:SSS');
    }
  }

  setTimeout(() => {
    onChange(dayjs().format(dateFormat));
  }, 5000);

  return (
    !uiSchema['ui:options']?.hide ? (
      <FormControl margin="normal">

        <TextField
          label="timestamp"
          defaultValue={dayjs().format(dateFormat)}
          style={{ width: '50%' }}
          disabled
          onChange={onChange}
        />
      </FormControl>
    ) : (<></>)
  )
};

export const TimeStampExtensionWithOptionsSchema = makeFieldSchemaFromZod(
  z.object({}),
  z.object({
    format: z
      .string()
      .optional()
      .describe(
        'Follow this page {{ https://github.com/dayjs/dayjs-website/blob/master/docs/display/format.md }} and find the right format for the timestamp',
      ),
  }),
).schema;
