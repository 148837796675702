import React, { useState, useEffect } from 'react';
import { TextField, Grid, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { fetchMal } from './apis';
import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';

interface ApplicationData {
    applicationID: string;
    applicationName: string;
    Description: string;
    product: string;
    alias: string;
    applicationStatus: string;
    businessCriticality: string;
    dataClassification: string;
    businessPlatform: string;
    productPortfolio: string;
    productGroup: string;
    owner: string;
    supportedBy: string;
    // Aliases of fields to handle deviations in form
    primaryProduct?: string; // product name
    portfolio?: string; // portfolio information
    appId?: string,
    appName?: string,
    description?: string;
    primaryProductProd?: string, // product group
    primaryProductProd0?: string, // portfolio
    primaryProductBusi?: string, // business platform
}

interface UiSchema {
    'ui:options'?: {
        show?: string[];
        mandate?: string[];
        override?: string[];
        selector?: 'appName' | 'appId';
    }
}
export const ApplicationIdField = ({ onChange, uiSchema }: {
    onChange: (data: any) => void;
    uiSchema: UiSchema;
}) => {
    const [applications, setApplications] = useState<ApplicationData[]>([]);
    const [selectedApplication, setSelectedApplication] = useState<ApplicationData | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const discoveryApi = useApi(discoveryApiRef);
    const fetchApi = useApi(fetchApiRef);

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const data = await fetchMal(discoveryApi, fetchApi);
                setApplications(data);
            } catch (error: any) {
                throw new Error('Error fetching applications:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchApplications();
    }, [discoveryApi, fetchApi]);

    const handleSelectionChange = (value: ApplicationData | null) => {
        setSelectedApplication(value);
        if (value) {
            onChange({
                appId: value.applicationID,
                appName: value.applicationName,
                alias: value.alias || '',
                description: value?.Description || '',
                primaryProduct: value?.product || '',
                primaryProductProd: value?.productGroup || '',
                primaryProductProd0: value?.productPortfolio || '',
                primaryProductBusi: value?.businessPlatform || '',
                businessCriticality: value?.businessCriticality || '',
                dataClassification: value?.dataClassification || '',
                applicationStatus: value?.applicationStatus || '',
                owner: value?.owner || '',
                supportedBy: value?.supportedBy || '',
            });
        } else {
            onChange(null);
        }
    };

    const getFieldValue = (field: string, application: ApplicationData | null): string => {
        if (!application) return '';
        switch (field) {
            case 'primaryProduct':
                return application.product || '';
            case 'primaryProductProd':
                return application.productGroup || '';
            case 'primaryProductProd0':
                return application.productPortfolio || '';
            case 'primaryProductBusi':
                return application.businessPlatform || '';
            case 'description':
                return application.Description || '';
            default:
                return application[field as keyof ApplicationData] || '';
        }
    };

    const updateFieldValue = (field: string, value: string, application: ApplicationData | null): ApplicationData => {
        if (!application) return {} as ApplicationData;

        const updatedApplication = { ...application };

        switch (field) {
            case 'primaryProduct':
                updatedApplication.product = value;
                break;
            case 'primaryProductProd':
                updatedApplication.productGroup = value;
                break;
            case 'primaryProductProd0':
                updatedApplication.productPortfolio = value;
                break;
            case 'primaryProductBusi':
                updatedApplication.businessPlatform = value;
                break;
            case 'description':
                updatedApplication.Description = value;
                break;
            default:
                updatedApplication[field as keyof ApplicationData] = value;
                break;
        }

        return updatedApplication;
    };
    const shouldShowAppName = uiSchema['ui:options']?.selector === 'appName' || !uiSchema['ui:options']?.selector;
    const shouldShowAppId = uiSchema['ui:options']?.selector === 'appId' || !uiSchema['ui:options']?.selector;

    const renderFields = () => {
        return (
            <Grid container direction="column" spacing={3}>
                {/* Show Fields (Non-Editable) */}
                {uiSchema['ui:options']?.show?.map((field: string) => {
                    const value = getFieldValue(field, selectedApplication);
                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                fullWidth
                                value={value || 'NA'}
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    )
                })}

                {/* Mandate Fields (Mandatory) */}
                {uiSchema['ui:options']?.mandate?.map((field: string) => {
                    const value = getFieldValue(field, selectedApplication);
                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                required
                                fullWidth
                                value={value}
                                onChange={(e) => {
                                    const updatedApplication = updateFieldValue(field, e.target.value, selectedApplication);
                                    handleSelectionChange(updatedApplication);
                                }}
                                variant="outlined"
                                error={!selectedApplication?.[field as keyof ApplicationData]}
                                helperText={
                                    !selectedApplication?.[field as keyof ApplicationData]
                                        ? `${field} is required.`
                                        : ''
                                }
                            />
                        </Grid>
                    )
                })}

                {/* Override Fields (Editable, Overrides API Values) */}
                {uiSchema['ui:options']?.override?.map((field: string) => {
                    const value = getFieldValue(field, selectedApplication);

                    return (
                        <Grid item key={field} xs={12}>
                            <TextField
                                label={field}
                                fullWidth
                                size="medium"
                                value={value}
                                onChange={(e) => {
                                    const updatedApplication = updateFieldValue(field, e.target.value, selectedApplication);
                                    handleSelectionChange(updatedApplication);
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };
    return (
        <Grid container spacing={2} alignItems="flex-start">

            {/* Selector Dropdowns */}
            <Grid item xs={6}>
                {shouldShowAppName && (
                    <Autocomplete
                        options={applications}
                        loading={loading}
                        getOptionLabel={(option) => option.applicationName || ''}
                        value={selectedApplication}
                        onChange={(_, value) => handleSelectionChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Application Name"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            </Grid>
            <Grid item xs={6}>
                {shouldShowAppId && (
                    <Autocomplete
                        options={applications}
                        loading={loading}
                        getOptionLabel={(option) => option.applicationID || ''}
                        value={selectedApplication}
                        onChange={(_, value) => handleSelectionChange(value)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Application ID"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                )}
            </Grid>

            {/* Dynamic Fields */}
            {renderFields()}

        </Grid>
    );
};