/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import RetryIcon from '@material-ui/icons/Replay';
import GitHubIcon from '@material-ui/icons/GitHub';
import { Link as RouterLink } from 'react-router-dom';
import { useWorkflowRuns, WorkflowRun } from '../useWorkflowRuns';
import { WorkflowRunStatus } from '../WorkflowRunStatus';
import SyncIcon from '@material-ui/icons/Sync';
import { buildRouteRef } from '../../routes';
import { getProjectNameFromEntity } from '../getProjectNameFromEntity';
import { Entity } from '@backstage/catalog-model';

import {
  EmptyState,
  Table,
  TableColumn,
  Link,
} from '@backstage/core-components';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
// import { getHostnameFromEntity } from '../getHostnameFromEntity';
import { getStatusDescription } from '../WorkflowRunStatus/WorkflowRunStatus';
import Grid from '@material-ui/core/Grid';
import { filteredWorkflowRuns } from '../filteredWorkflowRuns';
import { readGithubIntegrationConfigs } from '@backstage/integration';
import { EnvDeployer } from '../EnvDeployer';

// Utility function to truncate string at the first newline character
const truncateAtNewline = (str: string) => {
  const newlineIndex = str.indexOf('\n');
  return newlineIndex !== -1 ? str.substring(0, newlineIndex) : str;
};

const generatedColumns: TableColumn<Partial<WorkflowRun>>[] = [
  {
    title: 'ID',
    field: 'id',
    type: 'numeric',
    width: '150px',
  },
  {
    title: 'Message',
    field: 'message',
    highlight: true,
    render: row => {
      const LinkWrapper = () => {
        const routeLink = useRouteRef(buildRouteRef);
        const truncatedMessage = truncateAtNewline(row.message!);
        return (
          <Link
            component={RouterLink}
            to={routeLink({ id: row.id! })}
            title={row.message} // display full message on hover
          >
            {truncatedMessage}
          </Link>
        );
      };

      return <LinkWrapper />;
    },
  },
  {
    title: 'Source',
    render: row => (
      <Typography variant="body2" noWrap>
        <Typography paragraph variant="body2">
          {row.source?.branchName}
        </Typography>
        <Typography paragraph variant="body2">
          {row.source?.commit.hash}
        </Typography>
      </Typography>
    ),
  },
  {
    title: 'Workflow',
    field: 'workflowName',
  },
  {
    title: 'Status',
    customSort: (d1, d2) => {
      return getStatusDescription(d1).localeCompare(getStatusDescription(d2));
    },
    render: row => (
      <Box display="flex" alignItems="center">
        <WorkflowRunStatus status={row.status} conclusion={row.conclusion} />
      </Box>
    ),
  },
  {
    title: 'Actions',
    render: (row: Partial<WorkflowRun>) => (
      <Tooltip title="Rerun workflow">
        <IconButton onClick={row.onReRunClick}>
          <RetryIcon />
        </IconButton>
      </Tooltip>
    ),
    width: '10%',
  },
];

type Props = {
  loading: boolean;
  retry: () => void;
  runs?: WorkflowRun[];
  projectName: string;
  page: number;
  onChangePage: (page: number) => void;
  total: number;
  pageSize: number;
  onChangePageSize: (pageSize: number) => void;
};

export const WorkflowRunsTableView = ({
  projectName,
  loading,
  pageSize,
  page,
  retry,
  runs,
  onChangePage,
  onChangePageSize,
  total,
}: Props) => {
  return (
    <Table
      isLoading={loading}
      options={{ paging: true, pageSize, padding: 'dense' }}
      totalCount={total}
      page={page}
      actions={[
        {
          icon: () => <SyncIcon />,
          tooltip: 'Reload workflow runs',
          isFreeAction: true,
          onClick: () => retry(),
        },
      ]}
      data={runs ?? []}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangePageSize}
      style={{ width: '100%' }}
      title={
        <Box display="flex" alignItems="center">
          <GitHubIcon />
          <Box mr={1} />
          <Typography variant="h6">{projectName}</Typography>
        </Box>
      }
      columns={generatedColumns}
    />
  );
};

export const WorkflowRunsTable = ({
  entity,
  branch,
  projectTitle,
}: {
  entity: Entity;
  branch?: string;
  projectTitle: string;
}) => {
  const config = useApi(configApiRef);
  const projectName = getProjectNameFromEntity(entity);
  const hostname = readGithubIntegrationConfigs(
    config.getOptionalConfigArray('integrations.github') ?? [],
  )[0].host;
  const [owner, repo] = (projectName ?? '/').split('/');
  const [{ runs, ...tableProps }, { retry, setPage, setPageSize }] =
    useWorkflowRuns({
      hostname,
      owner,
      repo,
      branch,
    });

  const githubHost = hostname || 'github.com';
  const hasNoRuns = !tableProps.loading && !runs;

  return hasNoRuns ? (
    <EmptyState
      missing="data"
      title="No Workflow Data"
      description="This component has GitHub Actions enabled, but no data was found. Have you created any Workflows? Click the button below to create a new Workflow."
      action={
        <Button
          variant="contained"
          color="primary"
          href={`https://${githubHost}/${projectName}/actions/new`}
        >
          Create new Workflow
        </Button>
      }
    />
  ) : (
    <WorkflowRunsTableView
      {...tableProps}
      runs={runs}
      loading={tableProps.loading}
      projectName={projectTitle}
      retry={retry}
      onChangePageSize={setPageSize}
      onChangePage={setPage}
    />
  );
};


export const FilteredWorkflowRunsTable = ({
  entity,
  projectTitle,
  productTeamRepoName,
}: {
  entity: Entity;
  branch?: string;
  projectTitle: string;
  productTeamRepoName?: string;
}) => {
  const config = useApi(configApiRef);
  const projectName = getProjectNameFromEntity(entity);
  const hostname = readGithubIntegrationConfigs(
    config.getOptionalConfigArray('integrations.github') ?? [],
  )[0].host;
  const [owner, repo] = (projectName ?? '/').split('/');
  const [{ runs, ...tableProps }, { retry, setPage, setPageSize }] =
    filteredWorkflowRuns({
      hostname,
      owner,
      repo,
      productTeamRepoName
    });

  const hasNoRuns = !tableProps.loading && !runs

  return hasNoRuns ? (
    <>
      <Grid container spacing={3}>
        <Grid item xs={1}><p /><p /> <p /><p /><img style={{ width: '100%', alignContent: 'right' }} src="https://icon-library.com/images/info-icon-transparent/info-icon-transparent-21.jpg" alt="NEW" /></Grid>
        <Grid item xs={8}>
          <h1 style={{ lineHeight: 1.334, fontWeight: 700, fontSize: '1.5rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }} >Cloud9 CICD as a Service</h1>
          <p style={{ lineHeight: 1.5, fontWeight: 400, fontSize: '1rem', fontFamily: '"Helvetica Neue", Helvetica, Roboto, Arial, sans- serif;' }}> Incomplete registration for CICD as service. Click to re-enroll this project.</p>
        </Grid>

        <Grid item xs={3}>
          <p />
          <p />
          <Button
            variant="contained"
            color="primary"
            href="/create/templates/default/cicdaas-pipeline-ui-dev"
          >
            Enroll Now
          </Button>
        </Grid>
      </Grid>

    </>
  ) : (
    <>
      <EnvDeployer />
      <WorkflowRunsTableView
        {...tableProps}
        projectName={projectTitle}
        runs={runs}
        loading={tableProps.loading}
        retry={retry}
        onChangePageSize={setPageSize}
        onChangePage={setPage}
      />
    </>
  );

};
