/*
 * Copyright 2024 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { ScaffolderFormDecoratorsApi } from './types';
import { ScaffolderFormDecorator } from '@internal/backstage-plugin-scaffolder-react/alpha';

/** @alpha */
export class DefaultScaffolderFormDecoratorsApi
  implements ScaffolderFormDecoratorsApi
{
  private constructor(
    private readonly options: {
      decorators: Array<ScaffolderFormDecorator>;
    },
  ) {}

  static create(options?: { decorators: ScaffolderFormDecorator[] }) {
    return new DefaultScaffolderFormDecoratorsApi(
      options ?? { decorators: [] },
    );
  }

  async getFormDecorators(): Promise<ScaffolderFormDecorator[]> {
    return this.options.decorators;
  }
}
