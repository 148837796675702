import React from 'react';

const logoStyle: React.CSSProperties = {
  width: "75px",
  position: "relative",
  left: "-10px"
};

const titleStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center"
};

const LogoFull: React.FC = () => {
  return (
    <div style={titleStyle} className="sidebar-title">
      <img src="/D_T_Icon.png" style={logoStyle} alt="cloud9" />
      <h2>MUZIRIS</h2>
    </div>
  );
};

export default LogoFull;
