/* eslint-disable react-hooks/rules-of-hooks */
/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { useState } from 'react';
import useAsyncRetry from 'react-use/lib/useAsyncRetry';
import { githubActionsApiRef } from '../api/GithubActionsApi';
import { useApi, errorApiRef, configApiRef } from '@backstage/core-plugin-api';
import { WorkflowRun } from './useWorkflowRuns';

export function filteredWorkflowRuns({
  hostname,
  owner,
  repo,
  initialPageSize = 5,
  productTeamRepoName,
}: {
  hostname?: string;
  owner: string;
  repo: string;
  branch?: string;
  initialPageSize?: number;
  productTeamRepoName?: string;
}) {
  
  const api = useApi(githubActionsApiRef);
  const config = useApi(configApiRef);
  const errorApi = useApi(errorApiRef);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const {
    loading,
    value: runs,
    retry,
    error,
  } = useAsyncRetry<WorkflowRun[]>(async () => {
    // Get all workflows in the repository
    const repoWorkflowsData = await listAllRepoWorkFlows(config, repo);

    // Find the workflow ID for the given workflow name
    const workflowId = await getProductRepoNameBasedWorkFlowID(
      repoWorkflowsData,
      productTeamRepoName,
    );

    // Get all workflow runs for the given workflow ID
    const workflowData = await listAllWorkflowsByWorkFlowID(
      config,
      repo,
      workflowId,
    );

    // Filter the workflow runs based on the page and page size
    const workflowRunsData = await filterWorkDataBasedOnPageAndPageSize(
      workflowData,
      page,
      pageSize,
    );

    // Set the total count for pagination
    setTotal(workflowRunsData.total_count);

    // Return the workflow runs
    if (workflowRunsData.total_count !== 0) {
      return workflowRunsData.workflow_runs.map(
        (run: {
          name: any;
          head_commit: { message: any; id: any };
          id: any;
          head_branch: any;
          head_repository: { branches_url: string };
          status: any;
          conclusion: any;
          url: any;
          html_url: any;
        }) => ({
          workflowName: run.name ?? undefined,
          message: run.head_commit?.message,
          id: `${run.id}`,
          onReRunClick: async () => {
            try {
              await api.reRunWorkflow({
                hostname,
                owner,
                repo,
                runId: run.id,
              });
            } catch (e: any) {
              errorApi.post(e);
            }
          },
          source: {
            branchName: run.head_branch ?? undefined,
            commit: {
              hash: run.head_commit?.id,
              url: run.head_repository?.branches_url?.replace(
                '{/branch}',
                run.head_branch ?? '',
              ),
            },
          },
          status: run.status ?? undefined,
          conclusion: run.conclusion ?? undefined,
          url: run.url,
          githubUrl: run.html_url,
        }),
      );
    } 
      return undefined;
    
  }, [page, pageSize, repo, owner]);

  return [
    {
      page,
      pageSize,
      loading,
      runs,
      projectName: `${owner}/${repo}`,
      total,
      error,
    },
    {
      runs,
      setPage,
      setPageSize,
      retry,
    },
  ] as const;
}

// Get all workflow runs for the given workflow ID
async function listAllWorkflowsByWorkFlowID(
  config: any,
  repo: string,
  workflowId: any,
) {
  return await fetch(
    `${config.getString('backend.baseUrl')  }/api/github//list/workflow_runs`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
        agent: 'muziris',
      },
      body: JSON.stringify({
        repo: repo,
        workflow_id: workflowId,
      }),
    },
  ).then(data => data.json());
}

// Find the workflow ID for the given workflow name
async function getProductRepoNameBasedWorkFlowID(
  repoWorkflowsData: any,
  productTeamRepoName: string | undefined,
) {
  return await repoWorkflowsData.workflows.filter((w: any) => {
    return w.name === `CICDaaS workflow for repo ${  productTeamRepoName}`;
  })[0].id;
}

// Get all workflows in the repository
async function listAllRepoWorkFlows(config: any, repo: string) {
  return await fetch(
    `${config.getString('backend.baseUrl') 
      }/api/github/list/workflows?repo=${repo}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include',
      },
    },
  ).then(data => data.json());
}

// Filter the workflow runs based on the page and page size
async function filterWorkDataBasedOnPageAndPageSize(
  workflowRunsData: any,
  page: number,
  pageSize: number,
) {
  const start = page * pageSize;
  const end = start + pageSize;
  return {
    total_count: workflowRunsData.total_count,
    workflow_runs: workflowRunsData.workflow_runs.slice(start, end),
  };
}
