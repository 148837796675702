import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';

import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  AzureNetworkExtensionWithOptionsSchema,
  AzureNetworkExtension,
} from './AzureNetworkExtension';

export const AzureNetworkFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'AzureNetwork',
    component: AzureNetworkExtension,
    schema: AzureNetworkExtensionWithOptionsSchema,
  }),
);
