/***/
/**
 * Common functionalities for the utils plugin.
 *
 * @packageDocumentation
 */

/**
 * In this package you might for example declare types that are common
 * between the frontend and backend plugin packages.
 */
// export type CommonType = {
//   field: string;
// };

export function findValueByKey(obj: any, key: string) {
  // Check if the current object is an array
  if (Array.isArray(obj)) {
    // Iterate through the array
    for (const element of obj) {
      const value: any = findValueByKey(element, key);
      if (value !== undefined) {
        return value; // Return the value if found
      }
    }
  } else if (typeof obj === "object" && obj !== null) {
    // Iterate through the object properties
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        if (prop === key) {
          return obj[prop]; // Return the value if key matches
        }
        const value: any = findValueByKey(obj[prop], key);
        if (value !== undefined) {
          return value; // Return the value if found
        }
      }
    }
  }
  return undefined; // Key not found
}

export const setCookie = (
  name: string,
  value: string,
  minutes: number,
  path: string = "/",
  secure: boolean = true
) => {
  const date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=${path};${
    secure ? "secure;" : ""
  }SameSite=Strict`;
};

export const getCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift() ?? null;
  return null;
};

export const isTokenValid = (token: string | null): boolean => {
  return token !== null;
};

/**
 * Or you might declare some common constants.
 */
// export const COMMON_CONSTANT = 1;
export * from './ecosystemBuilderCreationConstants';