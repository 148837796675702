import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import {
  SignedInUserEmailExtension,
  validateSignedInUserEmailField,
  SignedInUserEmailExtensionWithOptionsSchema,
} from './SignedInUserEmailExtension';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';

export const SignedInUserEmailFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'signed-in-user',
    component: SignedInUserEmailExtension,
    validation: validateSignedInUserEmailField,
    schema: SignedInUserEmailExtensionWithOptionsSchema,
  }),
);
