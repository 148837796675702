import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  UpsertTopicConfig,
  UpsertConfigValidation,
} from './UpsertTopicConfigExtension';

export const UpsertTopicConfigFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'cc-upsert-topic-config',
    component: UpsertTopicConfig,
    validation: UpsertConfigValidation,
  }),
);
