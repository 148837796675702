import {
  scaffolderPlugin,
  // createScaffolderFieldExtension,
} from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import { IocodeValidator } from './IocodeValidationExtension';

export const IocodeValidationExtensions = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'Iocode-Validator',
    component: IocodeValidator
  }),
);
