import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import { fetchAzureADGroups, microsoftAzureToken } from '../api';
import { EXISTING_ADGROUP, HELPER_TEXT_EXISTING_ADGROUP } from '@internal/backstage-plugin-utils-common';

interface ExistingAdGroup {
    adgroup_name: string;
    read: boolean;
    write: boolean;
    admin: boolean;
}

interface ExistingAdGroupsFieldsProps {
    existingAdGroups: ExistingAdGroup[];
    handleAddExistingAdGroup: (existingAdGroup: ExistingAdGroup) => void;
    handleEditExistingAdGroup: (index: number, updatedExistingAdGroup: ExistingAdGroup) => void;
    handleRemoveExistingAdGroup: (index: number) => void;
}

export const ExistingAdGroupsFields: React.FC<ExistingAdGroupsFieldsProps> = ({
    existingAdGroups,
    handleAddExistingAdGroup,
    handleEditExistingAdGroup,
    handleRemoveExistingAdGroup,
}) => {
    const [existingAdGroupName, setExistingAdGroupName] = useState<string>('');
    const [existingAdGroupRead, setExistingAdGroupRead] = useState<boolean>(false);
    const [existingAdGroupWrite, setExistingAdGroupWrite] = useState<boolean>(false);
    const [existingAdGroupAdmin, setExistingAdGroupAdmin] = useState<boolean>(false);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [changesMade, setChangesMade] = useState<boolean>(false);
    const [addChangesMade, setAddChangesMade] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [errorText, setErrorText] = useState<string>(''); // New state for error text
    const accessToken = microsoftAzureToken();

    useEffect(() => {
        if (editIndex !== null) {
            const selectedExistingAdGroup = existingAdGroups[editIndex];
            const changes =
                selectedExistingAdGroup.adgroup_name !== existingAdGroupName ||
                selectedExistingAdGroup.read !== existingAdGroupRead ||
                selectedExistingAdGroup.write !== existingAdGroupWrite ||
                selectedExistingAdGroup.admin !== existingAdGroupAdmin;

            setChangesMade(changes);
        }
    }, [existingAdGroups, editIndex, existingAdGroupName, existingAdGroupRead, existingAdGroupWrite, existingAdGroupAdmin]);

    useEffect(() => {
        const changes = existingAdGroupName.trim() !== '' || existingAdGroupRead || existingAdGroupWrite || existingAdGroupAdmin;
        setAddChangesMade(changes);
    }, [existingAdGroupName, existingAdGroupRead, existingAdGroupWrite, existingAdGroupAdmin]);

    const clearExistingAdGroupFields = () => {
        setExistingAdGroupName('');
        setExistingAdGroupRead(false);
        setExistingAdGroupWrite(false);
        setExistingAdGroupAdmin(false);
        setEditIndex(null);
        setChangesMade(false);
        setAddChangesMade(false);
    };


    useEffect(() => {
        const validateAdGroup = async () => {
            if (existingAdGroupName.trim() === '') {
                setIsValid(false);
                setErrorText('AD Group cannot be empty.');
                return;
            }

            const isValidAdGroup = await checkExistingAdGroup(existingAdGroupName, accessToken);

            if (isValidAdGroup) {
                setIsValid(true);
                setErrorText('');
            } else {
                setIsValid(false);
                setErrorText('Invalid AD Group. Please enter a valid one.');
            }
        };

        validateAdGroup();
    }, [existingAdGroupName, accessToken]);

    const handleAddOrUpdateExistingAdGroup = () => {
        if (!isValid) {
            setErrorText('Invalid AD Group. Please enter a valid one.');
            setIsValid(false);
            return;
        }

        // Add or edit existing team member
        if (editIndex !== null) {
            handleEditExistingAdGroup(editIndex, {
                adgroup_name: existingAdGroupName,
                read: existingAdGroupRead,
                write: existingAdGroupWrite,
                admin: existingAdGroupAdmin,
            });
        } else {
            handleAddExistingAdGroup({
                adgroup_name: existingAdGroupName,
                read: existingAdGroupRead,
                write: existingAdGroupWrite,
                admin: existingAdGroupAdmin,
            });
        }
        clearExistingAdGroupFields();
    };


    const checkExistingAdGroup = async (existingAdGroupName: string, token: any) => {
        try {
            const checkAdJson = await fetchAzureADGroups(existingAdGroupName, token);
            const data = await checkAdJson;
            return data.value.length > 0; // Return true if the AD group exists
        } catch (error) {
            console.error('Error checking AD group:', error);
            return false;
        }
    };

    const handleEditClick = (index: number) => {
        const selectedExistingAdGroup = existingAdGroups[index];
        setExistingAdGroupName(selectedExistingAdGroup.adgroup_name);
        setExistingAdGroupRead(selectedExistingAdGroup.read);
        setExistingAdGroupWrite(selectedExistingAdGroup.write);
        setExistingAdGroupAdmin(selectedExistingAdGroup.admin);
        setEditIndex(index);
        setOpenDialog(true);
    };

    const handleRemoveClick = (index: number) => {
        handleRemoveExistingAdGroup(index);
        clearExistingAdGroupFields();
    };

    return (
        <div>
            <h3>{EXISTING_ADGROUP}</h3>
            <p>{HELPER_TEXT_EXISTING_ADGROUP}</p>
            <div>
                {existingAdGroups.map((member, index) => (
                    <div key={index}>
                        {`${member.adgroup_name} - Read: ${member.read}, Write: ${member.write}, Admin: ${member.admin}`}
                        <IconButton color='primary' onClick={() => handleEditClick(index)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color='primary' onClick={() => handleRemoveClick(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
            </div>

            <Button color='primary' variant='contained' onClick={() => setOpenDialog(true)}>
                Add Existing Ad Groups
            </Button>

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Typography variant='h6'>
                            {editIndex !== null ? 'Edit the Information Entered' : 'Add Existing AD Group'}
                        </Typography>
                        <IconButton color='primary' onClick={() => setOpenDialog(false)}>
                            <CancelIcon />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Existing AD Group Name"
                                value={existingAdGroupName}
                                variant='outlined'
                                margin='normal'
                                fullWidth
                                onChange={(e) => setExistingAdGroupName(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={existingAdGroupRead} onChange={() => setExistingAdGroupRead(!existingAdGroupRead)} />}
                                label="Read Access"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={existingAdGroupWrite} onChange={() => setExistingAdGroupWrite(!existingAdGroupWrite)} />}
                                label="Write Access"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                control={<Checkbox checked={existingAdGroupAdmin} onChange={() => setExistingAdGroupAdmin(!existingAdGroupAdmin)} />}
                                label="Admin Access"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <IconButton color='primary' onClick={handleAddOrUpdateExistingAdGroup} disabled={!changesMade && !addChangesMade || !isValid}>
                        <AddIcon />
                    </IconButton>
                    {errorText && <Typography variant="caption" color="error">{errorText}</Typography>}
                </DialogActions>
            </Dialog>
        </div>
    );
};