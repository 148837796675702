/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Alert from '@material-ui/lab/Alert';
import { useAsync } from 'react-use';
import { makeFieldSchemaFromZod } from '@internal/backstage-plugin-scaffolder';
import { z } from 'zod';
import { AzureSubscription } from '../types';
import { microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { getCookie, isTokenValid, setCookie } from '@internal/backstage-plugin-utils-common';

// todo: move acquireTokenSilent() to template page after scope lookup for multi-custom-field templates.
type AzureSubscriptionOptions = {
  id: string;
  name: string;
};

type ResourceGroupOptions = {
  id: string;
  resourceGroupName: string;
  location: string;
  tags: Record<string, string>;
};

type ListOfResourceOptions = {
  resource_id: string;
  resourceName: string;
  resourceType: string;
  resourceSKU: Record<string, string>;
};

type ResourceGroupPolicyOptions = {
  rg_displayName: string;
  rg_policyDefinitionId: string;
  rg_scope: string;
  rg_assignmentId: string;
  rg_policy_location: string;
  rg_policy_type: string;
};

type ResourcePolicyOptions = {
  resource_displayName: string;
  resource_policyDefinitionId: string;
  resource_scope: string;
  resource_assignmentId: string;
  resource_policy_location: string;
  resource_policy_type: string;
};

type AzureResourcePicker = {
  resourceGroup: string;
  resources: string;
  resourceGroup_policies: string;
  resource_policies: string;
  subscription: string;
  appServicePlan: string;
  logicApp: string;
  storageAccount: string;
};

type AzureAppServicePlanOptions = {
  appserviceplan_id: string;
  appserviceplan_name: string;
  subscription: string;
  resourceGroup: string;
};

type ListOfLogicApps = {
  logicApp_id: string;
  logicApp_name: string;
  logicApp_Vnet: string;
  resourceGroup: string;
  subscription: string;
};

type ListOfStorageAccount = {
  sa_id: string;
  sa_name: string;
};

function compareStrings(a: string, b: string) {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : a > b ? 1 : 0;
}

function removeUndefinedDisplayName(jsonArray: any, keyToCheck: string) {
  return jsonArray.filter((item: any) => item[keyToCheck] !== undefined);
}

export const AzureSubscriptionExtension = ({
  onChange,
  schema,
  rawErrors,
  required,
  // formData,
  idSchema,
  uiSchema,
  uiSchemaOptions,
}: any) => {
  // const config = getMSALConfig();
  const [rgList, setRgList] = React.useState<ResourceGroupOptions[]>([]);
  const [resourceList, setResourceList] = React.useState<
    ListOfResourceOptions[]
  >([]);
  const [rgPolicyList, setRgPolicyList] = React.useState<
    ResourceGroupPolicyOptions[]
  >([]);
  const [resourcePolicyList, setResourcePolicyList] = React.useState<
    ResourcePolicyOptions[]
  >([]);
  const [appServicePlanList, setAppServicePlanList] = React.useState<
    AzureAppServicePlanOptions[]
  >([]);
  const [logicAppList, setLogicAppList] = React.useState<ListOfLogicApps[]>([]);
  const [storageAccountList, setStorageAccountList] = React.useState<
    ListOfStorageAccount[]
  >([]);

  const [userToken, setUserToken] = React.useState<string | undefined>();
  const [ResourceGroupLoader, setResourceGroupLoader] = React.useState(true);
  const [ResourceLoader, setResourceLoader] = React.useState(true);
  const [rgPolicyLoader, setRgpolicyLoader] = React.useState(true);
  const [resourcePolicyLoader, setResourecPolicyLoader] = React.useState(true);
  const [appServicePlanLoader, setAppServicePlanLoader] = React.useState(true);
  const [logicAppLoader, setLogicAppLoader] = React.useState(true);
  const [storageAccountLoader, setStorageAccountLoader] = React.useState(true);

  const [resourceGroup, setResourceGroup] =
    React.useState<ResourceGroupOptions>();
  const [resource, setResource] = React.useState<ListOfResourceOptions>();
  const [resourceGroupPolicy, setResourceGroupPolicy] =
    React.useState<ResourceGroupPolicyOptions>();
  const [resourcePolicyValue, setResourcePolicy] =
    React.useState<ResourcePolicyOptions>();
  const [appServicePlanValue, setAppServicePlan] =
    React.useState<AzureAppServicePlanOptions>();
  const [logicAppValue, setLogicApp] = React.useState<ListOfLogicApps>();
  const [storageAccountValue, setStorageAccount] =
    React.useState<ListOfStorageAccount>();

  const [subscriptionDetails, setSubscriptionDetails] = React.useState<
    string | undefined
  >();
  const [selectedResourceGroup, setResourGroupDetails] = React.useState<
    string | undefined
  >();
  const [selectedAppServicePlan, setAppServicePlanDetails] = React.useState<
    string | undefined
  >();
  const [selectedResource, setResourceDetails] = React.useState<
    string | undefined
  >();

    uiSchemaOptions = uiSchema['ui:options'];
    const valueToFilter = uiSchemaOptions?.filter || 'all';

  type AzureResourcePickerKeys = keyof AzureResourcePicker;

  let selectorFields: AzureResourcePickerKeys[] = uiSchema['ui:options']
    ?.selector
    ? (uiSchema['ui:options'].selector as AzureResourcePickerKeys[])
    : [
      'subscription',
      'resourceGroup',
      'resources',
      'resourceGroup_policies',
      'resource_policies',
      'appServicePlan',
      'logicApp',
      'storageAccount',
    ];

  if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription'];
  } else if (
    selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription'];
  } else if (
    !selectorFields.includes('subscription') &&
    selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription', 'resourceGroup'];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription', 'resourceGroup', 'resources'];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = [
      'subscription',
      'resourceGroup',
      'resourceGroup_policies',
    ];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = [
      'subscription',
      'resourceGroup',
      'resources',
      'resource_policies',
    ];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription', 'resourceGroup', 'appServicePlan'];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    selectorFields.includes('logicApp') &&
    !selectorFields.includes('storageAccount')
  ) {
    selectorFields = [
      'subscription',
      'resourceGroup',
      'appServicePlan',
      'logicApp',
    ];
  } else if (
    !selectorFields.includes('subscription') &&
    !selectorFields.includes('resourceGroup') &&
    !selectorFields.includes('resources') &&
    !selectorFields.includes('resourceGroup_policies') &&
    !selectorFields.includes('resource_policies') &&
    !selectorFields.includes('appServicePlan') &&
    !selectorFields.includes('logicApp') &&
    selectorFields.includes('storageAccount')
  ) {
    selectorFields = ['subscription', 'resourceGroup', 'storageAccount'];
  }
  const microsoft = useApi(microsoftAuthApiRef);
  // Fetch Azure subscription
  const { value, loading, error } = useAsync(async (): Promise<any> => {
    let token = getCookie('azureAccessToken') as string;

    if (!isTokenValid(token)) {
      token = await microsoft.getAccessToken(["https://management.azure.com/user_impersonation", "profile", "offline_access", "email", "openid"]);
      setCookie('azureAccessToken', token, 60); // Set token in a cookie with a 60-minute expiry
    }
    if (userToken === undefined) {
      setUserToken(token);
    }
    return await fetch('https://management.azure.com/subscriptions/?api-version=2020-01-01', {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    }).then(res => res.json());

  }, [userToken]);

  if (error) {
    console.error(error);
    return (
      <Alert severity="error">
        Error could not fetch Azure subscriptions.{' '}
      </Alert>
    );
  }

  const azureSubscriptions: AzureSubscriptionOptions[] = [];

  try {
    const filteredData = value?.value?.filter((data: AzureSubscription) =>{
    
      if(valueToFilter === 'all') return true;

      const subscriptionType = data.tags?.subscription_type || data.tags?.Subscription_Type

      return subscriptionType === valueToFilter; })
      
      filteredData?.map((data: AzureSubscription) =>
      azureSubscriptions.push({
        id: data.subscriptionId,
        name: data.displayName,
      }),
    );
  } catch (err: any) {
    console.error(err);
    <Alert severity="error">{err.message}</Alert>;
  }

  async function fetchResourceGroupList(
    subscriptionId: string,
  ): Promise<ResourceGroupOptions[]> {
    try {
      const resourceGroups: ResourceGroupOptions[] = [];

      const fetchPage = async (nextLink?: string) => {
        const response = await fetch(
          nextLink ||
          `https://management.azure.com/subscriptions/${subscriptionId}/resourcegroups?api-version=2021-04-01`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          },
        );

        if (!response.ok) {
          throw new Error('Failed to fetch resource groups');
        }

        const data = await response.json();
        const pageResourceGroups =
          data.value?.map((eachObj: any) => ({
            id: eachObj.id,
            resourceGroupName: eachObj.name,
            location: eachObj.location,
            tags: eachObj.tags,
          })) || [];

        resourceGroups.push(...pageResourceGroups);

        const nextLINKS = data?.nextLink;

        if (nextLINKS) {
          await fetchPage(nextLINKS); // Recursive call to fetch the next page
        }
      };

      await fetchPage();
      setResourceGroupLoader(false);

      return resourceGroups;
    } catch (error: any) {
      console.log('Could not fetch List of Resource Groups', error);
      setResourceGroupLoader(false);
      return [];
    }
  }

  // fetch Azure resources
  async function fetchAzureResources(rgId: string) {
    try {
      return await fetch(
        `https://management.azure.com${rgId}/resources?api-version=2021-04-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            resource_id: eachObj.id,
            resourceName: eachObj.name,
            resourceType: eachObj.type,
            resourceSKU: eachObj.sku,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Resources');
    }
  }

  // fetch Azure App Service Plan
  async function fetchAppServicePlan(rgId: string) {
    try {
      return await fetch(
        `https://management.azure.com${rgId}/providers/Microsoft.Web/serverfarms?api-version=2022-03-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            appserviceplan_id: eachObj.id,
            appserviceplan_name: eachObj.name,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of App Service Plan ');
    }
  }
  // fetch Storage Account
  async function fetchStorageAccount(rgId: string) {
    try {
      return await fetch(
        `https://management.azure.com${rgId}/providers/Microsoft.Storage/storageAccounts?api-version=2022-09-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            sa_id: eachObj.id,
            sa_name: eachObj.name,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Storage Account ');
    }
  }
  // fetch Azure Logic App List
  async function fetchLogicApp(appid: string) {
    try {
      return await fetch(
        `https://management.azure.com${appid}/sites?api-version=2022-03-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            logicApp_id: eachObj.id,
            logicApp_name: eachObj.name,
            logicApp_Vnet: eachObj.virtualNetworkSubnetId,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Logic App ');
    }
  }

  // Fetch Resource Group Polcieis
  async function fetchRgPolicyList(rgId: string) {
    try {
      return await fetch(
        `https://management.azure.com${rgId}/providers/Microsoft.Authorization/policyAssignments?api-version=2022-06-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            rg_displayName: eachObj.properties.displayName,
            rg_policyDefinitionId: eachObj.properties.policyDefinitionId,
            rg_scope: eachObj.properties.scope,
            rg_assignmentId: eachObj.id,
            rg_policy_location: eachObj.location,
            rg_policy_type: eachObj.type,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Resource Group Policies');
    }
  }

  // fetch Resource Policy List
  async function fetchResourceList(resourceID: string) {
    try {
      return await fetch(
        `https://management.azure.com${resourceID}/providers/Microsoft.Authorization/policyAssignments?api-version=2022-06-01`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        },
      )
        .then(res => res.json())
        .then(data => data.value)
        .then(result =>
          result?.map((eachObj: any) => ({
            resource_displayName: eachObj.properties.displayName,
            resource_policyDefinitionId: eachObj.properties.policyDefinitionId,
            resource_scope: eachObj.properties.scope,
            resource_assignmentId: eachObj.id,
            resource_policy_location: eachObj.location,
            resource_policy_type: eachObj.type,
          })),
        );
    } catch (error: any) {
      console.log('Could not fetch List of Resource Policies');
    }
  }

  // onchange for subscription
  const onSelectHandleSubscriptionChange = async (_event: any, value: any) => {
    setResourceGroupLoader(true);
    setResourceGroup(undefined);
    setRgList([]);
    setResourceList([]);
    onChange(value);
    setSubscriptionDetails(value);

    // fetch call to get Resource Group function
    if (
      selectorFields &&
      selectorFields.includes('resourceGroup') &&
      value?.id !== null
    ) {
      const rgResponse = await fetchResourceGroupList(value.id || null);
      setRgList(rgResponse);
      setResourceGroupLoader(false);
    } else {
      setResourceGroupLoader(false);
    }
  };

  // onchnage for resourcegroup
  const onSelect = async (event: any, value: any) => {
    setResourceLoader(true);
    setResource(undefined);
    setResourceList([]);
    const resourceGroupDetails = value;
    onChange({ subscriptionDetails, resourceGroupDetails });
    setResourceGroup(event.target.value as ResourceGroupOptions);
    setResourGroupDetails(resourceGroupDetails);

    // fetcg Call to get rg policies
    if (
      selectorFields &&
      selectorFields.includes('resourceGroup_policies') &&
      value?.id !== null
    ) {
      setRgpolicyLoader(true);
      setResourceGroupPolicy(undefined);
      setRgPolicyList([]);
      const rgPolicyResponse = await fetchRgPolicyList(value.id);
      setRgPolicyList(rgPolicyResponse);
      setRgpolicyLoader(false);
    } else {
      setRgpolicyLoader(false);
    }
    // fetch call to get Resources
    if (
      selectorFields &&
      selectorFields.includes('resources') &&
      value?.id !== null
    ) {
      const resourceResponse = await fetchAzureResources(value.id || null);
      setResourceList(resourceResponse);
      setResourceLoader(false);
    } else {
      setResourceLoader(false);
    }

    // fetch call to get App Service Plan
    if (
      selectorFields &&
      selectorFields.includes('appServicePlan') &&
      value?.id !== null
    ) {
      const appServicePlanResponce = await fetchAppServicePlan(
        value.id || null,
      );
      setAppServicePlanList(appServicePlanResponce);
      setAppServicePlanLoader(false);
    } else {
      setAppServicePlanLoader(false);
    }

    // fetch call to get Storage Account
    if (
      selectorFields &&
      selectorFields.includes('storageAccount') &&
      value?.id !== null
    ) {
      const storageAccountResponce = await fetchStorageAccount(
        value.id || null,
      );
      setStorageAccountList(storageAccountResponce);
      setStorageAccountLoader(false);
    } else {
      setStorageAccountLoader(false);
    }
  };

  // onchange for resources
  const onResourceSelect = async (event: any, value: any) => {
    setResourecPolicyLoader(true);
    setResourcePolicyList([]);
    const resourceDetails = value;
    onChange({ subscriptionDetails, selectedResourceGroup, resourceDetails });
    setResource(event.target.value as ListOfResourceOptions);
    setResourceDetails(resourceDetails);

    // Fetch Resource Level Policies
    if (
      selectorFields &&
      selectorFields.includes('resource_policies') &&
      value?.resource_id !== null
    ) {
      const rsListResponse = await fetchResourceList(value.resource_id);
      setResourcePolicyList(rsListResponse);
      setResourecPolicyLoader(false);
    } else {
      setResourecPolicyLoader(false);
    }
  };

  const onResourceGroupPolicySelect = (event: any, value: any) => {
    const resourceGroupPolicyDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      resourceGroupPolicyDetails,
    });
    setResourceGroupPolicy(event.target.value as ResourceGroupPolicyOptions);
  };
  const onResourcePolicySelect = (event: any, value: any) => {
    const resourcePolicyDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      selectedResource,
      resourcePolicyDetails,
    });
    setResourcePolicy(event.target.value as ResourcePolicyOptions);
  };

  // On changes for App Service Plan
  const onAppServicePlanSelect = async (event: any, value: any) => {
    setLogicAppLoader(true);
    setLogicAppList([]);
    const appServicePlanDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      appServicePlanDetails,
    });
    setAppServicePlan(event.target.value as AzureAppServicePlanOptions);
    setAppServicePlanDetails(appServicePlanDetails);

    // fetch call to get LogicApp
    if (
      selectorFields &&
      selectorFields.includes('logicApp') &&
      value?.appserviceplan_id !== null
    ) {
      const logicAppResponce = await fetchLogicApp(
        value.appserviceplan_id || null,
      );
      setLogicAppList(logicAppResponce);
      setLogicAppLoader(false);
    } else {
      setLogicAppLoader(false);
    }
  };

  const onLogicAppSelect = (event: any, value: any) => {
    const logicAppDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      selectedAppServicePlan,
      logicAppDetails,
    });
    setLogicApp(event.target.value as ListOfLogicApps);
  };

  const onStorageAccountSelect = (event: any, value: any) => {
    const storageAccountDetails = value;
    onChange({
      subscriptionDetails,
      selectedResourceGroup,
      storageAccountDetails,
    });
    setStorageAccount(event.target.value as ListOfStorageAccount);
  };

  //   Azure subscription Props
  const AzureSubscriptionProps = {
    options: azureSubscriptions.sort(
      (a: AzureSubscriptionOptions, b: AzureSubscriptionOptions) => {
        return compareStrings(a.name, b.name);
      },
    ),
    getOptionLabel: (option: AzureSubscriptionOptions) => option.name,
    getOptionSelected: (
      option: AzureSubscriptionOptions,
      value: AzureSubscriptionOptions,
    ) => option.name === value.name,
  };

  //   ResourceGroup Props
  const resourceGroupProps = {
    options: removeUndefinedDisplayName(rgList, 'resourceGroupName').sort(
      (a: ResourceGroupOptions, b: ResourceGroupOptions) => {
        return compareStrings(a.resourceGroupName, b.resourceGroupName);
      },
    ),
    getOptionLabel: (option: ResourceGroupOptions) => option.resourceGroupName,
    getOptionSelected: (
      option: ResourceGroupOptions,
      value: ResourceGroupOptions,
    ) => option.resourceGroupName === value.resourceGroupName,
  };

  // Resource Props
  const resourceProps = {
    options: removeUndefinedDisplayName(resourceList, 'resourceName').sort(
      (a: ListOfResourceOptions, b: ListOfResourceOptions) => {
        return compareStrings(a.resourceName, b.resourceName);
      },
    ),
    getOptionLabel: (option: ListOfResourceOptions) => option.resourceName,
    getOptionSelected: (
      option: ListOfResourceOptions,
      value: ListOfResourceOptions,
    ) => option.resourceName === value.resourceName,
  };

  // App Service Plan Props
  const appServicePlanProps = {
    options: removeUndefinedDisplayName(
      appServicePlanList,
      'appserviceplan_name',
    ).sort((a: AzureAppServicePlanOptions, b: AzureAppServicePlanOptions) => {
      return compareStrings(a.appserviceplan_name, b.appserviceplan_name);
    }),
    getOptionLabel: (option: AzureAppServicePlanOptions) =>
      option.appserviceplan_name,
    getOptionSelected: (
      option: AzureAppServicePlanOptions,
      value: AzureAppServicePlanOptions,
    ) => option.appserviceplan_name === value.appserviceplan_name,
  };

  // Logic App Props
  const logicAppProps = {
    options: removeUndefinedDisplayName(logicAppList, 'logicApp_name').sort(
      (a: ListOfLogicApps, b: ListOfLogicApps) => {
        return compareStrings(a.logicApp_name, b.logicApp_name);
      },
    ),
    getOptionLabel: (option: ListOfLogicApps) => option.logicApp_name,
    getOptionSelected: (option: ListOfLogicApps, value: ListOfLogicApps) =>
      option.logicApp_name === value.logicApp_name,
  };

  // Storage Account Props
  const storageAccountProps = {
    options: removeUndefinedDisplayName(storageAccountList, 'sa_name').sort(
      (a: ListOfStorageAccount, b: ListOfStorageAccount) => {
        return compareStrings(a.sa_name, b.sa_name);
      },
    ),
    getOptionLabel: (option: ListOfStorageAccount) => option.sa_name,
    getOptionSelected: (
      option: ListOfStorageAccount,
      value: ListOfStorageAccount,
    ) => option.sa_name === value.sa_name,
  };

  // Resource Group Policy Props
  const resourceGroupPolicyProps = {
    options: removeUndefinedDisplayName(rgPolicyList, 'rg_displayName').sort(
      (a: ResourceGroupPolicyOptions, b: ResourceGroupPolicyOptions) => {
        return compareStrings(a.rg_displayName, b.rg_displayName);
      },
    ),
    getOptionLabel: (option: ResourceGroupPolicyOptions) =>
      option.rg_displayName,
    getOptionSelected: (
      option: ResourceGroupPolicyOptions,
      value: ResourceGroupPolicyOptions,
    ) => option.rg_displayName === value.rg_displayName,
  };

  // Resource Policy Props
  const resourcePolicyProps = {
    options: removeUndefinedDisplayName(
      resourcePolicyList,
      'resource_displayName',
    ).sort((a: ResourcePolicyOptions, b: ResourcePolicyOptions) => {
      return compareStrings(a.resource_displayName, b.resource_displayName);
    }),
    getOptionLabel: (option: ResourcePolicyOptions) =>
      option.resource_displayName,
    getOptionSelected: (
      option: ResourcePolicyOptions,
      value: ResourcePolicyOptions,
    ) => option.resource_displayName === value.resource_displayName,
  };

  const Title = schema.title ? schema.title : 'Azure Subscription';

  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors?.length > 0}
      >
        {!selectorFields ||
          (selectorFields && selectorFields.includes('subscription') && (
            <Autocomplete
              id={idSchema?.$id}
              onChange={onSelectHandleSubscriptionChange}
              loading={loading}
              {...AzureSubscriptionProps}
              clearOnEscape
              // need to add on inout change
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label={Title}
                  variant="standard"
                  required={required}
                />
              )}
            />
          ))}
        {schema.description && (
          <FormHelperText>{schema.description}</FormHelperText>
        )}
      </FormControl>

      {selectorFields && selectorFields.includes('resourceGroup') && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0}
        >
          <Autocomplete
            id={idSchema?.$id}
            onChange={onSelect}
            loading={ResourceGroupLoader}
            {...resourceGroupProps}
            clearOnEscape
            value={resourceGroup}
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Resource Group"
                variant="standard"
                required={required}
                onChange={e => console.log(e.target.value)}
              />
            )}
          />
          {schema.description && (
            <FormHelperText>Choose the required Resource Group</FormHelperText>
          )}
        </FormControl>
      )}

      {selectorFields && selectorFields.includes('resources') && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0}
        >
          <Autocomplete
            id={idSchema?.$id}
            value={resource}
            onChange={onResourceSelect}
            loading={ResourceLoader}
            {...resourceProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of Resources"
                variant="standard"
                required={required}
              />
            )}
          />
          {schema.description && (
            <FormHelperText>Choose the required resources</FormHelperText>
          )}
        </FormControl>
      )}

      {selectorFields && selectorFields.includes('appServicePlan') && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0}
        >
          <Autocomplete
            id={idSchema?.$id}
            value={appServicePlanValue}
            onChange={onAppServicePlanSelect}
            loading={appServicePlanLoader}
            {...appServicePlanProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of App Service Plan"
                variant="standard"
                required={required}
              />
            )}
          />
          {schema.description && (
            <FormHelperText>
              Choose the required App Service Plan
            </FormHelperText>
          )}
        </FormControl>
      )}
      {selectorFields && selectorFields.includes('logicApp') && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0}
        >
          <Autocomplete
            id={idSchema?.$id}
            value={logicAppValue}
            onChange={onLogicAppSelect}
            loading={logicAppLoader}
            {...logicAppProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of Logic Apps"
                variant="standard"
                required={required}
              />
            )}
          />
          {schema.description && (
            <FormHelperText>Choose the required Logic App</FormHelperText>
          )}
        </FormControl>
      )}
      {selectorFields && selectorFields.includes('storageAccount') && (
        <FormControl
          margin="normal"
          required={required}
          error={rawErrors?.length > 0}
        >
          <Autocomplete
            id={idSchema?.$id}
            value={storageAccountValue}
            onChange={onStorageAccountSelect}
            loading={storageAccountLoader}
            {...storageAccountProps}
            clearOnEscape
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="List of Storage Accounts"
                variant="standard"
                required={required}
              />
            )}
          />
          {schema.description && (
            <FormHelperText>
              Choose the required Storage Account{' '}
            </FormHelperText>
          )}
        </FormControl>
      )}
      {rgList && rgList.length > 0
        ? selectorFields &&
        selectorFields.includes('resourceGroup_policies') && (
          <FormControl
            margin="normal"
            required={required}
            error={rawErrors?.length > 0}
          >
            <Autocomplete
              id={idSchema?.$id}
              value={resourceGroupPolicy}
              onChange={onResourceGroupPolicySelect}
              loading={rgPolicyLoader}
              {...resourceGroupPolicyProps}
              clearOnEscape
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label="Azure Resource Group Policies"
                  variant="standard"
                  required={required}
                  des
                />
              )}
            />
            {schema.description && (
              <FormHelperText>
                Choose the Policy assigned on the resource group selected
                above
              </FormHelperText>
            )}
          </FormControl>
        )
        : null}

      {resourceList && resourceList.length > 0
        ? selectorFields &&
        selectorFields.includes('resource_policies') && (
          <FormControl
            margin="normal"
            required={required}
            error={rawErrors?.length > 0}
          >
            <Autocomplete
              id={idSchema?.$id}
              value={resourcePolicyValue}
              onChange={onResourcePolicySelect}
              loading={resourcePolicyLoader}
              {...resourcePolicyProps}
              clearOnEscape
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  label=" Azure Resource Policy List"
                  variant="standard"
                  required={required}
                />
              )}
            />
            {schema.description && (
              <FormHelperText>
                Choose the policy assigned for the resource selected
              </FormHelperText>
            )}
          </FormControl>
        )
        : null}
    </>
  );
};

export const AzureSubscriptionExtensionWithOptionsSchema =
  makeFieldSchemaFromZod(
    z.object({}),
    z.object({
      selector: z
        .array(
          z.enum([
            'subscription',
            'resourceGroup',
            'resources',
            'resourceGroup_policies',
            'resource_policies',
            'appServicePlan',
            'logicApp',
            'storageAccount',
          ]),
        )
        .optional()
        .describe('Allow user Select Field in the form. Defaults to []'),
    }),
  ).schema;